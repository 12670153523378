import { TokenHelper } from "../common/TokenHelper";

export class UserModel {

  public country: string = ""; //  UK, ES etc.
  public email: string = "";
  public expiry: Date = new Date();
  public uId: string = "";

  private token: string = "";

  constructor(userToken: string) {

    if (userToken?.length > 0) {
      this.token = userToken;

      let tokenHelper = new TokenHelper();
      let tokenData = tokenHelper.decodeToken(userToken);

      this.email = <string>tokenHelper.getTokenVar("email");
      this.country = <string>tokenHelper.getTokenVar("country");
      this.uId = <string>tokenHelper.getTokenVar("uId");
    }

  }
}
