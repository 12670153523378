export const environment = {
  production: false,
  API_BASE_URL:
    'https://clawstore-web-staging-gkh8ccbkgxgvhyga.uksouth-01.azurewebsites.net',
  //'http://localhost:5554',
  // msal config details
  //  from: https://medium.com/@ankit4b/a-simplified-guide-to-msal-integration-with-angular-16-24c15cc8130e
  // mslConfig: {
  //   clientId: '',
  //   authority: '',
  //   redirectUri: '',
  // },
  // AD: {
  //   instance: 'https://login.microsoftonline.com/',
  //   domain: 'MonsterenergyCorp.onmicrosoft.com',
  //   tenantId: 'f01d65ba-e57e-4f8f-8143-a43ed634f3c2',
  //   clientId: 'f14e4337-0587-49a4-bd93-5da78ae4b440',
  //   callbackPath: 'Login/LoginResponse',
  //   postLogoutRedirectUri: 'http://localhost:4200/login',
  //   redirectUri: 'http://localhost:4200/dashboard',
  //   popUp: false,
  //   cacheLocation: 'localStorage',
  // },
  redirectUri: 'https://clawstore-staging.monsterenergy.com',
};
