<div>
  <label
    [for]="id"
    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    >{{ label }}</label
  >
  <input
    type="text"
    [id]="id"
    [name]="name"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    [placeholder]="placeholder"
    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-custom-green focus:border-custom-green block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
  />
</div>
