import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-on-off-badge',
  templateUrl: './on-off-badge.component.html',
})
export class OnOffBadgeComponent {
  @Input() isOn!: boolean;
  @Input() text!: string;
}
