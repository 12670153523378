import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
})
export class TextFieldComponent {
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() placeholder: string = '';

  @Output() valueChange = new EventEmitter<string>();

  onChange(newValue: string) {
    this.value = newValue;
    this.valueChange.emit(newValue);
  }
}
