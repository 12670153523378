import { NgModule, isDevMode, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import * as SignaturePad from 'signature_pad';
import { NgOptimizedImage } from '@angular/common';

import { TradeDevelopmentManagersService } from './services/swagger.gen';
import { StoresListService } from './services/swagger.gen';
import { StoresSummaryService } from './services/swagger.gen';
import { FavouritesService } from './services/swagger.gen';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LeftsidebarComponent } from './layout/leftsidebar/leftsidebar.component';
import { NotificationsComponent } from './layout/notifications/notifications.component';
import { CallListComponent } from './call-list/call-list.component';
import { StoreSummaryComponent } from './store-summary/store-summary.component';
import { EditStoreModalComponent } from './store-summary/edit-store-modal/edit-store-modal.component';
import { LoadingSpinnerComponent } from './_shared/components/loading-spinner/loading-spinner.component';
import { PostcodePipe } from './call-list/postcode.pipe';
import { SortIconPipe } from './call-list/sort-icon.pipe';
import { RemoveIconPipe } from './call-list/remove-icon.pipe';
import { AddStoreNoteComponent } from './store-summary/add-store-note/add-store-note.component';
import { DropdownComponent } from './survey/components/dropdown/dropdown.component';
import { TextFieldComponent } from './survey/components/text-field/text-field.component';
import { NumberInputComponent } from './survey/components/number-input/number-input.component';
import { SurveyPageComponent } from './survey/survey-page/survey-page.component';
import { PhotoUploadComponent } from './survey/components/photo-upload/photo-upload.component';
import { DropdownMultiComponent } from './survey/components/dropdown-multi/dropdown-multi.component';
import { SurveysService } from './services/swagger.gen';
import { BottomNavBarComponent } from './_shared/components/bottom-nav-bar/bottom-nav-bar.component';
import { DayLandingPageComponent } from './day-landing-page/day-landing-page.component';
import { AppSettings } from './common/appSettings';
import { environment } from '../environments/environment';
import { DistributionRepeaterComponent } from './survey/components/distribution-repeater/distribution-repeater.component';
import { StoreLocationCardComponent } from './survey/components/store-location-card/store-location-card.component';
import { SkuSelectorComponent } from './survey/components/sku-selector/sku-selector.component';
import { LocationSelectorComponent } from './survey/components/location-selector/location-selector.component';
import { SingleImageSelectorComponent } from './survey/components/single-image-selector/single-image-selector.component';
import { LocationSelectorModalComponent } from './survey/components/location-selector/location-selector-modal.component';
import { OnOffBadgeComponent } from './_shared/components/on-off-badge/on-off-badge.component';
import { LocationDiscontinueModalComponent } from './survey/components/distribution-repeater/location-discontinue-modal.component';
import { EndStoreVisitComponent } from './survey/end-store-visit/end-store-visit.component';
import { OrderPageComponent } from './survey/order-page/order-page.component';
import { OrderSelectorModalComponent } from './survey/order-page/order-selector-modal/order-selector-modal.component';
import { OrderSummaryComponent } from './survey/order-page/order-summary/order-summary.component';
import { OrderSignatureComponent } from './survey/order-page/order-signature/order-signature.component';
import { OrderReceiptComponent } from './survey/order-page/order-receipt/order-receipt.component';
import { WholesalePageComponent } from './wholesale-page/wholesale-page.component';
import { FeedbackPageComponent } from './feedback-page/feedback-page.component';

// const msalConfig = environment.mslConfig;
// const isIE =
//   window.navigator.userAgent.indexOf('MSIE ') > -1 ||
//   window.navigator.userAgent.indexOf('Trident/') > -1;

// MSAL Imports

import {
  MsalModule,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalInterceptor } from '@azure/msal-angular';
import { MainComponent } from './layout/main/main.component';

const msalConfig = {
  auth: {
    clientId: 'd4898d85-d392-4a94-a70a-96cbaf8ea134',
    authority:
      'https://login.microsoftonline.com/f01d65ba-e57e-4f8f-8143-a43ed634f3c2',
    redirectUri: environment.redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

const msalAngularConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ['user.read'],
  },
};

function MSALInstanceFactory(): PublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return msalAngularConfig;
}

const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map<string, Array<string>>([
    ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
  ]),
};

function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return msalInterceptorConfig;
}

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LeftsidebarComponent,
    NotificationsComponent,
    CallListComponent,
    LoadingSpinnerComponent,
    PostcodePipe,
    SortIconPipe,
    RemoveIconPipe,
    EditStoreModalComponent,
    StoreSummaryComponent,
    AddStoreNoteComponent,
    DropdownComponent,
    TextFieldComponent,
    NumberInputComponent,
    SurveyPageComponent,
    PhotoUploadComponent,
    DropdownMultiComponent,
    BottomNavBarComponent,
    DayLandingPageComponent,
    DistributionRepeaterComponent,
    StoreLocationCardComponent,
    LocationSelectorComponent,
    SingleImageSelectorComponent,
    LocationSelectorModalComponent,
    OnOffBadgeComponent,
    LocationDiscontinueModalComponent,
    EndStoreVisitComponent,
    OrderPageComponent,
    OrderSelectorModalComponent,
    OrderSummaryComponent,
    OrderSignatureComponent,
    OrderReceiptComponent,
    WholesalePageComponent,
    FeedbackPageComponent,
    MainComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    BrowserAnimationsModule,
    // MsalModule.forRoot(
    //   new PublicClientApplication({
    //     auth: {
    //       clientId: msalConfig.clientId,
    //       authority: msalConfig.authority,
    //       redirectUri: msalConfig.redirectUri,
    //     },
    //     cache: {
    //       cacheLocation: 'localStorage',
    //       storeAuthStateInCookie: isIE,
    //     },
    //   }),
    //   {
    //     interactionType: InteractionType.Redirect, // Msal Guard Configuration
    //     authRequest: {
    //       scopes: ['user.read'],
    //     },
    //   },
    //   {
    //     interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
    //     protectedResourceMap: new Map([
    //       ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    //     ]),
    //   }
    // ),
    NgOptimizedImage,
    SkuSelectorComponent,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory()
    ),
  ],

  exports: [],
  providers: [
    TradeDevelopmentManagersService,
    StoresListService,
    FavouritesService,
    StoresSummaryService,
    SurveysService,
    AppSettings,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
