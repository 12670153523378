import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortIcon',
})
export class SortIconPipe implements PipeTransform {
  transform(
    column: string,
    sortColumn: string,
    sortDirection: 'asc' | 'desc'
  ): string {
    if (column !== sortColumn) {
      return 'fa-solid fa-sort';
    }
    return sortDirection === 'asc'
      ? 'fa-solid fa-sort-up'
      : 'fa-solid fa-sort-down';
  }
}
