import { Component, Input } from '@angular/core';
import { OrderedItem } from '../order-selector-modal/order-selector-modal.component';

@Component({
  selector: 'app-order-receipt',
  templateUrl: './order-receipt.component.html',
})
export class OrderReceiptComponent {
  @Input() photoData: string = '';
  @Input() orderItems: OrderedItem[] = [];
  @Input() isWholesale: boolean = false;

  print() {}

  email() {
    // Implement email functionality
    console.log('Email functionality to be implemented');
  }
}
