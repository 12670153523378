import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postcode',
})
export class PostcodePipe implements PipeTransform {
  transform(value: string): string {
    if (!value || value === '') {
      return '';
    }
    return value.split(' ')[0];
  }
}
