import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SurveysService,
  SurveysUIModel,
  QuestionsUIModel,
} from '../../services/swagger.gen';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-survey-page',
  templateUrl: './survey-page.component.html',
})
export class SurveyPageComponent implements OnInit {
  surveyData: SurveysUIModel | undefined;
  currentStep = 0;
  isLoading = true;
  countryCode: string = '';
  storeId: string = '';
  locationResponses: LocationResponse[] = [];

  constructor(
    private surveysService: SurveysService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.storeId = this.route.snapshot.params['storeId'];
    this.route.paramMap.subscribe((params) => {
      const country = params.get('country');
      if (country) {
        this.countryCode = country.toUpperCase();
        console.log('Country code:', this.countryCode);
      }
      this.getSurveyData();
    });
  }

  getSurveyData() {
    this.isLoading = true;
    this.surveysService.getSurvey(this.countryCode).subscribe({
      next: (data) => {
        console.log('Received survey data:', data);
        if (data && data.length > 0) {
          this.surveyData = data[0];
          // order by group order in surveySteps
          if (this.surveyData.surveySteps) {
            this.surveyData.surveySteps = this.surveyData.surveySteps.sort(
              (a, b) => a.surveyStep!.groupOrder! - b.surveyStep!.groupOrder!
            );
          }
        } else {
          console.warn('No survey data received');
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error fetching survey data:', error);
        this.isLoading = false;
      },
      complete: () => {
        console.log('Survey data fetch completed');
      },
    });
  }

  getQuestionComponent(template: string | undefined): string {
    if (!template) return 'Unknown';
    switch (template.trim()) {
      case 'FreeText':
        return 'Text Field';
      case 'DropdownList':
        return 'Dropdown';
      case 'NumberPlusMinus':
        return 'Number Input';
      case 'PhotoUploader':
        return 'Photo Upload';
      case 'YesNo':
        return 'Dropdown';
      case 'MultiSelectDropdown':
        return 'MultiSelectDropDown';
      default:
        return 'Unknown';
    }
  }

  getDropdownOptions(question: QuestionsUIModel): DropdownOption[] {
    if (question.questionTemplate?.trim() === 'YesNo') {
      return [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
      ];
    }
    if (!question.values) return [];
    return Object.entries(question.values).map(([value, label]) => ({
      value,
      label,
    }));
  }

  hideNavBar(step: number): boolean {
    return (
      this.surveyData?.surveySteps?.[step]?.surveyStep?.componentName ===
        'DistributionRepeater' ||
      this.surveyData?.surveySteps?.[step]?.surveyStep?.componentName ===
        'OrdersComponent'
    );
  }

  nextStep() {
    if (this.currentStep < (this.surveyData?.surveySteps?.length || 0) - 1) {
      this.currentStep++;
      return;
    }

    if (this.currentStep + 1 >= this.surveyData?.surveySteps?.length!) {
      this.router.navigate(['end-store-visit/', this.storeId]);
    }
  }

  previousStep() {
    if (this.currentStep <= 0) {
      Swal.fire({
        title: 'Are you sure you want to leave the survey?',
        text: 'You will lose all progress.',
        icon: 'warning',
        confirmButtonText: '<i class="fas fa-check"></i> Confirm',
        cancelButtonText: 'Close',
        customClass: {
          icon: 'no-border',
          confirmButton: 'btn btn-success bg-custom-green text-black',
          title: 'swal2-title-small',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate([
            'store-summary/',
            this.storeId,
            this.countryCode,
          ]);
        }
      });
      return;
    }

    this.currentStep--;
  }
}

export interface DropdownOption {
  value: string;
  label: string;
}

export interface LocationResponse {
  locationId: number;
  photo: string | null;
  compliant: boolean;
  location: string;
  storeLocation: string;
  skus: SkuResponse[];
  completed: boolean;
  createdAt: 'Entry' | 'Exit';
}

export interface SkuResponse {
  skuId: string;
  number: number;
}
