import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallListComponent } from './call-list/call-list.component';
import { StoreSummaryComponent } from './store-summary/store-summary.component';
import { SurveyPageComponent } from './survey/survey-page/survey-page.component';
import { DayLandingPageComponent } from './day-landing-page/day-landing-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { EndStoreVisitComponent } from './survey/end-store-visit/end-store-visit.component';
import { WholesalePageComponent } from './wholesale-page/wholesale-page.component';
import { FeedbackPageComponent } from './feedback-page/feedback-page.component';

const routes: Routes = [
  { path: '', component: CallListComponent },
  { path: 'day-landing', component: DayLandingPageComponent },
  { path: 'store-list', component: CallListComponent },
  {
    path: 'store-summary/:id/:country',
    component: StoreSummaryComponent,
  },
  { path: 'survey/:country/:storeId', component: SurveyPageComponent },
  { path: 'end-store-visit/:storeId', component: EndStoreVisitComponent },
  { path: 'wholesale', component: WholesalePageComponent },
  { path: 'feedback', component: FeedbackPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
