import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { CommonModule } from '@angular/common';
import { LocationResponse } from '../../survey-page/survey-page.component';

@Component({
  selector: 'app-sku-selector',
  templateUrl: './sku-selector.component.html',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
})
export class SkuSelectorComponent implements OnInit, OnDestroy {
  skus: Sku[] = [];
  groupedSkus: GroupWithSkus[] = [];

  @Input()
  response!: LocationResponse;

  skuGroups: SkuGroup[] = [
    {
      name: 'CORE',
      image: 'assets/images/product-groups/core.png',
    },
    {
      name: 'JUICED',
      image: 'assets/images/product-groups/juiced.png',
    },
    {
      name: 'ULTRA',
      image: 'assets/images/product-groups/ultra.png',
    },
    {
      name: 'ADDITIONS',
      image: 'assets/images/product-groups/additions.png',
    },
    {
      name: 'BURN',
      image: 'assets/images/product-groups/burn.png',
    },
    {
      name: 'BPM',
      image: 'assets/images/product-groups/bpm.png',
    },
    {
      name: 'NALU',
      image: 'assets/images/product-groups/nalu.png',
    },
    {
      name: 'POWER PLAY',
      image: 'assets/images/product-groups/power-play.png',
    },
    {
      name: 'PREDATOR',
      image: 'assets/images/product-groups/predator.png',
    },
    {
      name: 'REIGN',
      image: 'assets/images/product-groups/reign.png',
    },
    {
      name: 'REIGN STORM',
      image: 'assets/images/product-groups/reign-storm.png',
    },
    {
      name: 'RELENTLESS',
      image: 'assets/images/product-groups/relentless.png',
    },
  ];

  showScrollButton = false;
  private scrollListener: any;
  private incrementInterval: any;
  private decrementInterval: any;
  private readonly HOLD_DELAY = 500; // 0.5 seconds before rapid increment/decrement
  private readonly RAPID_INTERVAL = 50; // How often (ms) to increment/decrement during rapid mode

  ngOnInit() {
    this.skus = this.getSkus();
    // Create groups with their SKUs
    const groupMap = new Map<string, Sku[]>();

    for (const sku of this.skus) {
      if (!groupMap.has(sku.group)) {
        groupMap.set(sku.group, []);
      }
      groupMap.get(sku.group)?.push(sku);
    }

    // Convert map to array of GroupWithSkus
    this.groupedSkus = Array.from(groupMap.entries()).map(([name, skus]) => ({
      skuGroup: this.skuGroups.find((group) => group.name === name)!,
      skus,
    }));

    //alphabetical order of sku groups
    this.groupedSkus.sort((a, b) =>
      a.skuGroup.name.localeCompare(b.skuGroup.name)
    );

    // Add scroll listener
    this.scrollListener = () => {
      this.showScrollButton = window.scrollY > 100; // Show after 100px of scroll
    };
    window.addEventListener('scroll', this.scrollListener);
  }

  ngOnDestroy() {
    // Clean up scroll listener
    window.removeEventListener('scroll', this.scrollListener);
  }

  skuTap(id: number): void {
    let amt = this.getSkuAmount(id);
    if (amt == undefined) {
      clearTimeout(this.decrementInterval);
      clearInterval(this.decrementInterval);
      clearTimeout(this.incrementInterval);
      clearInterval(this.incrementInterval);
      this.addSkuAmount(id);
    }
  }

  addSkuAmount(id: number): void {
    let amt = this.getSkuAmount(id);
    if (amt == undefined) {
      this.response.skus.push({ skuId: id.toString(), number: 1 });
    } else {
      this.response.skus.find((sku) => sku.skuId === id.toString())!.number++;
    }
  }

  getSkuAmount(id: number): number | undefined {
    if (this.response.skus === undefined) {
      return;
    }
    return this.response.skus.find((sku) => sku.skuId === id.toString())
      ?.number;
  }

  removeSkuAmount(id: number): void {
    let amt = this.getSkuAmount(id);
    if (amt == undefined) {
      return;
    } else {
      if (amt == 1) {
        this.response.skus = this.response.skus.filter(
          (sku) => sku.skuId !== id.toString()
        );
      } else {
        this.response.skus.find((sku) => sku.skuId === id.toString())!.number--;
      }
    }
  }

  startIncrement(skuId: number, event: TouchEvent) {
    event.preventDefault(); // Prevent context menu
    this.addSkuAmount(skuId);
    this.incrementInterval = setTimeout(() => {
      this.incrementInterval = setInterval(() => {
        this.addSkuAmount(skuId);
      }, this.RAPID_INTERVAL);
    }, this.HOLD_DELAY);
  }

  stopIncrement() {
    clearTimeout(this.incrementInterval);
    clearInterval(this.incrementInterval);
  }

  startDecrement(skuId: number, event: TouchEvent) {
    event.preventDefault(); // Prevent context menu
    this.removeSkuAmount(skuId);
    this.decrementInterval = setTimeout(() => {
      this.decrementInterval = setInterval(() => {
        this.removeSkuAmount(skuId);
      }, this.RAPID_INTERVAL);
    }, this.HOLD_DELAY);
  }

  stopDecrement() {
    clearTimeout(this.decrementInterval);
    clearInterval(this.decrementInterval);
  }

  scrollToGroup(group: SkuGroup) {
    const groupElement = document.getElementById(group.name);
    if (groupElement) {
      const headerOffset = 100;
      const elementPosition = groupElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  skuNumberChange(event$: Event) {
    const currentTarget = event$.currentTarget as any;
    const id = currentTarget.id;
    const value = currentTarget.value;
    let amt = this.getSkuAmount(id);
    if (amt == undefined) {
      this.response.skus.push({ skuId: id.toString(), number: value });
    } else {
      this.response.skus.find((sku) => sku.skuId === id.toString())!.number = value;
    }
  }

  getSkus(): Sku[] {
    let skus: Sku[] = [];

    skus.push({
      id: 1,
      name: 'ASSAULT GREEN',
      image:
        'assets/images/root-product/ADDITIONS/ASSAULT GREEN/Default-Assault-Green-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 2,
      name: 'ASSAULT GREY',
      image:
        'assets/images/root-product/ADDITIONS/ASSAULT GREY/DE-Assault-Grey-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 3,
      name: 'LEWIS HAMILTON ZERO',
      image:
        'assets/images/root-product/ADDITIONS/LEWIS HAMILTON ZERO/Default-Lewis-Hamilton-Zero-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 4,
      name: 'LH44',
      image:
        'assets/images/root-product/ADDITIONS/LH44/Default-Lewis-Hamilton-44-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 5,
      name: 'MULE',
      image: 'assets/images/root-product/ADDITIONS/MULE/Default-Mule-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 6,
      name: 'NITRO',
      image:
        'assets/images/root-product/ADDITIONS/NITRO/Default-Nitro-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 7,
      name: 'NITRO COSMIC PEACH',
      image:
        'assets/images/root-product/ADDITIONS/NITRO COSMIC PEACH/Default-Nitro-Cosmic-Peach-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 8,
      name: 'RESERVE KIWI STRAWBERRY',
      image:
        'assets/images/root-product/ADDITIONS/RESERVE KIWI STRAWBERRY/DE-Reserve-Kiwi-Strawberry-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 9,
      name: 'RESERVE ORANGE DREAMSICLE',
      image:
        'assets/images/root-product/ADDITIONS/RESERVE ORANGE DREAMSICLE/DE-Reserve-Orange-Dreamsicle-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 10,
      name: 'RESERVE WATERMELON',
      image:
        'assets/images/root-product/ADDITIONS/RESERVE WATERMELON/BE-Reserve-Watermelon-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 11,
      name: 'RESERVE WHITE PINEAPPLE',
      image:
        'assets/images/root-product/ADDITIONS/RESERVE WHITE PINEAPPLE/Default-Reserve-White-Pineapple-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 12,
      name: 'ROSSI THE DOCTOR',
      image:
        'assets/images/root-product/ADDITIONS/ROSSI THE DOCTOR/DE-Rossi-The-Doctor-500ml.png',
      group: 'ADDITIONS',
    });
    skus.push({
      id: 13,
      name: 'Berry Red',
      image:
        'assets/images/root-product/BPM/Berry Red/Default-Berry-Red-500ml.png',
      group: 'BPM',
    });
    skus.push({
      id: 14,
      name: 'Mango',
      image: 'assets/images/root-product/BPM/Mango/Default-Mango-500ml.png',
      group: 'BPM',
    });
    skus.push({
      id: 15,
      name: 'Orange',
      image: 'assets/images/root-product/BPM/Orange/Default-Orange-500ml.png',
      group: 'BPM',
    });
    skus.push({
      id: 16,
      name: 'Peach',
      image: 'assets/images/root-product/BPM/Peach/Default-Peach-500ml.png',
      group: 'BPM',
    });
    skus.push({
      id: 17,
      name: 'Sour Twist',
      image:
        'assets/images/root-product/BPM/Sour Twist/Default-Sour-Twist-500ml.png',
      group: 'BPM',
    });
    skus.push({
      id: 18,
      name: 'APPLE KIWI',
      image:
        'assets/images/root-product/BURN/APPLE KIWI/Default-Apple-Kiwi.png',
      group: 'BURN',
    });
    skus.push({
      id: 19,
      name: 'APPLE KIWI',
      image:
        'assets/images/root-product/BURN/APPLE KIWI/Apple Kiwi 250ml/HU-Apple-Kiwi-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 20,
      name: 'APPLE KIWI',
      image:
        'assets/images/root-product/BURN/APPLE KIWI/Apple Kiwi 330ml/RU-Apple-Kiwi-330ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 21,
      name: 'APPLE KIWI',
      image:
        'assets/images/root-product/BURN/APPLE KIWI/Apple Kiwi 355ml/FR-Apple-Kiwi-355ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 22,
      name: 'APPLE KIWI',
      image:
        'assets/images/root-product/BURN/APPLE KIWI/Apple Kiwi 500ml/Default-Apple-Kiwi-500ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 23,
      name: 'CHERRY',
      image: 'assets/images/root-product/BURN/CHERRY/Default-Cherry-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 24,
      name: 'DARK ENERGY',
      image:
        'assets/images/root-product/BURN/DARK ENERGY/Default-Dark-Energy-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 25,
      name: 'FRUIT PUNCH',
      image:
        'assets/images/root-product/BURN/FRUIT PUNCH/Default-Fruit-Punch-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 26,
      name: 'FURY',
      image: 'assets/images/root-product/BURN/FURY/Default-Fury-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 27,
      name: 'GUAVA',
      image: 'assets/images/root-product/BURN/GUAVA/Default-Guava-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 28,
      name: 'LEMON ICE',
      image:
        'assets/images/root-product/BURN/LEMON ICE/Default-Lemon-355ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 29,
      name: 'MANGO',
      image: 'assets/images/root-product/BURN/MANGO/Default-Mango-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 30,
      name: 'ORIGINAL',
      image:
        'assets/images/root-product/BURN/ORIGINAL/Default-Original-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 31,
      name: 'PASSION PUNCH',
      image:
        'assets/images/root-product/BURN/PASSION PUNCH/Default-Passion-Punch-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 32,
      name: 'SOUR TWIST',
      image:
        'assets/images/root-product/BURN/SOUR TWIST/Default-Sour-Twist-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 33,
      name: 'ZERO',
      image: 'assets/images/root-product/BURN/ZERO/Default-Zero-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 34,
      name: 'ZERO PEACH',
      image:
        'assets/images/root-product/BURN/ZERO PEACH/Default-Peach-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 35,
      name: 'ZERO RASPBERRY',
      image:
        'assets/images/root-product/BURN/ZERO RASPBERRY/Default-Zero-Raspberry-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 36,
      name: 'ZERO WATERMELON',
      image:
        'assets/images/root-product/BURN/ZERO WATERMELON/Default-Zero-Watermelon-250ml.png',
      group: 'BURN',
    });
    skus.push({
      id: 37,
      name: 'ABSOLUTE ZERO',
      image:
        'assets/images/root-product/CORE/ABSOLUTE ZERO/DE-Absolute-Zero-500ml.png',
      group: 'CORE',
    });
    skus.push({
      id: 38,
      name: 'GREEN ZERO',
      image:
        'assets/images/root-product/CORE/GREEN ZERO/DE-Zero-Sugar-500ml.png',
      group: 'CORE',
    });
    skus.push({
      id: 39,
      name: 'ORIGINAL GREEN',
      image:
        'assets/images/root-product/CORE/ORIGINAL GREEN/AT-Original-Green-355ml - Copy.png',
      group: 'CORE',
    });
    skus.push({
      id: 40,
      name: 'AUSSIE LEMONADE',
      image:
        'assets/images/root-product/JUICED/AUSSIE LEMONADE/DE-Aussie-Lemonade-500ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 41,
      name: 'BAD APPLE',
      image:
        'assets/images/root-product/JUICED/BAD APPLE/Default-Bad-Apple-500ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 42,
      name: 'KHAOS BLACK',
      image:
        'assets/images/root-product/JUICED/KHAOS BLACK/Default-Khaos-Black-500ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 43,
      name: 'KHAOS ORANGE',
      image:
        'assets/images/root-product/JUICED/KHAOS ORANGE/Default-Khaos-Orange-500ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 44,
      name: 'KHAOTIC',
      image: 'assets/images/root-product/JUICED/KHAOTIC/Default-Khaotic.png',
      group: 'JUICED',
    });
    skus.push({
      id: 45,
      name: 'MANGO LOCO',
      image:
        'assets/images/root-product/JUICED/MANGO LOCO/CH-Mango-Loco-355ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 46,
      name: 'MIXXD PUNCH',
      image:
        'assets/images/root-product/JUICED/MIXXD PUNCH/BE-Mixxd-Punch-500ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 47,
      name: 'MONARCH',
      image:
        'assets/images/root-product/JUICED/MONARCH/Default-Monarch-500ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 48,
      name: 'PACIFIC PUNCH',
      image:
        'assets/images/root-product/JUICED/PACIFIC PUNCH/Default-Pacific-Punch-500ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 49,
      name: 'PIPELINE PUNCH',
      image:
        'assets/images/root-product/JUICED/PIPELINE PUNCH/Default-Pipeline-Punch-500ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 50,
      name: 'RIO PUNCH',
      image:
        'assets/images/root-product/JUICED/RIO PUNCH/Default-Rio-Punch-500ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 51,
      name: 'RIPPER',
      image:
        'assets/images/root-product/JUICED/RIPPER/Default-Ripper-500ml.png',
      group: 'JUICED',
    });
    skus.push({
      id: 52,
      name: 'EXOTIC',
      image: 'assets/images/root-product/NALU/EXOTIC/BE-Exotic-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 53,
      name: 'FROST',
      image: 'assets/images/root-product/NALU/FROST/BE-Frost-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 54,
      name: 'GINGER',
      image:
        'assets/images/root-product/NALU/GINGER/BE-Greent-Tea-Ginger-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 55,
      name: 'HIBISCUS',
      image: 'assets/images/root-product/NALU/HIBISCUS/BE-Hibiscus-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 56,
      name: 'LEVANDER',
      image: 'assets/images/root-product/NALU/LEVANDER/BE_Levander-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 57,
      name: 'MELON',
      image: 'assets/images/root-product/NALU/MELON/BE-Melon-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 58,
      name: 'ORIGINAL',
      image: 'assets/images/root-product/NALU/ORIGINAL/BE-Original-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 59,
      name: 'PASSION',
      image: 'assets/images/root-product/NALU/PASSION/BE-Passion-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 60,
      name: 'PASSION FRUIT',
      image:
        'assets/images/root-product/NALU/PASSION FRUIT/BE-Passion-Fruit-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 61,
      name: 'REFRESH',
      image: 'assets/images/root-product/NALU/REFRESH/BE-Refresh-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 62,
      name: 'STRAWBERRY',
      image:
        'assets/images/root-product/NALU/STRAWBERRY/BE-Strawberry-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 63,
      name: 'YUZU',
      image: 'assets/images/root-product/NALU/YUZU/BE-Yuzu-250ml.png',
      group: 'NALU',
    });
    skus.push({
      id: 64,
      name: 'APPLE KIWI',
      image:
        'assets/images/root-product/PLAY/APPLE KIWI/Default-Apple-Kiwi-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 65,
      name: 'CITRUS',
      image:
        'assets/images/root-product/PLAY/CITRUS/Default-Citrus-Peach-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 66,
      name: 'FRUIT PUNCH',
      image:
        'assets/images/root-product/PLAY/FRUIT PUNCH/Default-Fruit-Punch-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 67,
      name: 'GOLD SPARK',
      image:
        'assets/images/root-product/PLAY/GOLD SPARK/Default-Gold-Spark-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 68,
      name: 'LIGHT',
      image: 'assets/images/root-product/PLAY/LIGHT/Default-Light-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 69,
      name: 'MANGO BLUE',
      image:
        'assets/images/root-product/PLAY/MANGO BLUE/Default-Mango-Blue-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 70,
      name: 'ORIGINAL',
      image:
        'assets/images/root-product/PLAY/ORIGINAL/Default-Original-250ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 71,
      name: 'ORIGINAL ZERO',
      image:
        'assets/images/root-product/PLAY/ORIGINAL ZERO/Default-Original-Zero-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 72,
      name: 'PASSION FRUIT',
      image:
        'assets/images/root-product/PLAY/PASSION FRUIT/Default-Passion-Fruit-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 73,
      name: 'RASPBERRY',
      image:
        'assets/images/root-product/PLAY/RASPBERRY/Default-Raspberry-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 74,
      name: 'SUGAR FREE',
      image:
        'assets/images/root-product/PLAY/SUGAR FREE/Default-Sugar-Free-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 75,
      name: 'WATERMELON',
      image:
        'assets/images/root-product/PLAY/WATERMELON/Default-Watermelon-440ml.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 76,
      name: 'ZERO SUGAR',
      image:
        'assets/images/root-product/PLAY/ZERO SUGAR/Default-Zero-Sugar-440ml - Copy.png',
      group: 'POWER PLAY',
    });
    skus.push({
      id: 77,
      name: 'FRUIT PUNCH',
      image:
        'assets/images/root-product/PREDATOR/FRUIT PUNCH/Default-Fruit-Punch-500ml.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 78,
      name: 'GOLD STRIKE',
      image:
        'assets/images/root-product/PREDATOR/GOLD STRIKE/Default-Gold-Strike-250m.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 79,
      name: 'MALT SMASH',
      image:
        'assets/images/root-product/PREDATOR/MALT SMASH/Default-Malt-Smash-400ml-Bottle.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 80,
      name: 'Mango Mayhem Blue bottle',
      image:
        'assets/images/root-product/PREDATOR/Mango Mayhem Blue bottle/Default-Mango-Mayham-Blue Bottle.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 81,
      name: 'Mango Mayhem Blue Can',
      image:
        'assets/images/root-product/PREDATOR/Mango Mayhem Blue Can/Default-Mango-Mayhem-Blue-500ml.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 82,
      name: 'Mango Mayhem Orange Can',
      image:
        'assets/images/root-product/PREDATOR/Mango Mayhem Orange Can/Default-Mango-Mayhem-Orange-250ml.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 83,
      name: 'MEAN GREEN',
      image:
        'assets/images/root-product/PREDATOR/MEAN GREEN/Default-Mean-Green-250ml.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 84,
      name: 'PEACH',
      image:
        'assets/images/root-product/PREDATOR/PEACH/Default-Peach-500ml.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 85,
      name: 'Purple Rain Can',
      image:
        'assets/images/root-product/PREDATOR/Purple Rain Can/Default-Purple-Rain-500ml.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 86,
      name: 'Purple Rain Can - Bottle 350ml',
      image:
        'assets/images/root-product/PREDATOR/Purple Rain Can - Bottle 350ml/Default-Purple-Rain Bottle.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 87,
      name: 'Purple Rain Can - Bottle 400ml',
      image:
        'assets/images/root-product/PREDATOR/Purple Rain Can - Bottle 400ml/Default-Purple-Rain Bottle.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 88,
      name: 'RED APPLE',
      image:
        'assets/images/root-product/PREDATOR/RED APPLE/BG-Red-Apple-250ml.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 89,
      name: 'RED DAWN',
      image:
        'assets/images/root-product/PREDATOR/RED DAWN/Default-Red-Dawn-591ml-Bottle.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 90,
      name: 'SPICY GINGER',
      image:
        'assets/images/root-product/PREDATOR/SPICY GINGER/Default-Spicy-Ginger-500ml.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 91,
      name: 'Tropical-250ml Can',
      image:
        'assets/images/root-product/PREDATOR/Tropical-250ml Can/Default-Tropical-250ml.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 92,
      name: 'Tropical-350ml bottle',
      image:
        'assets/images/root-product/PREDATOR/Tropical-350ml bottle/Default-Tropical-350ml-Bottle.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 93,
      name: 'Tropical-500ml Can',
      image:
        'assets/images/root-product/PREDATOR/Tropical-500ml Can/Default-Tropical-500ml.png',
      group: 'PREDATOR',
    });
    skus.push({
      id: 94,
      name: 'Lemon-HZD-250ml',
      image:
        'assets/images/root-product/REIGN/Lemon-HZD-250ml/BE-Lemon-HZD-250ml.png',
      group: 'REIGN',
    });
    skus.push({
      id: 95,
      name: 'Lemon-HZD-500ml',
      image:
        'assets/images/root-product/REIGN/Lemon-HZD-500ml/Default-Lemon-HZD-500ml.png',
      group: 'REIGN',
    });
    skus.push({
      id: 96,
      name: 'MANG O MATIC',
      image:
        'assets/images/root-product/REIGN/MANG O MATIC/Default-Mango-O-Matic-500ml.png',
      group: 'REIGN',
    });
    skus.push({
      id: 97,
      name: 'Melon-Mania 250ml',
      image:
        'assets/images/root-product/REIGN/Melon-Mania 250ml/BE-Melon-Mania-250ml.png',
      group: 'REIGN',
    });
    skus.push({
      id: 98,
      name: 'Melon-Mania 500ml',
      image:
        'assets/images/root-product/REIGN/Melon-Mania 500ml/Default-Melon-Mania-500ml.png',
      group: 'REIGN',
    });
    skus.push({
      id: 99,
      name: 'ORANGE DREAMSICLE',
      image:
        'assets/images/root-product/REIGN/ORANGE DREAMSICLE/BE-Orange-Dreamsicle-250ml.png',
      group: 'REIGN',
    });
    skus.push({
      id: 100,
      name: 'PEACH FIZZ',
      image:
        'assets/images/root-product/REIGN/PEACH FIZZ/BE-Peach-Fizz-250ml.png',
      group: 'REIGN',
    });
    skus.push({
      id: 101,
      name: 'RAINBOW SHERBET',
      image:
        'assets/images/root-product/REIGN/RAINBOW SHERBET/DEFAULT-Reignbow-Sherbert.png',
      group: 'REIGN',
    });
    skus.push({
      id: 102,
      name: 'RAZZLE BERRY',
      image:
        'assets/images/root-product/REIGN/RAZZLE BERRY/BE-Razzle-Berry-250ml.png',
      group: 'REIGN',
    });
    skus.push({
      id: 103,
      name: 'SOUR APPLE',
      image:
        'assets/images/root-product/REIGN/SOUR APPLE/Default-Sour-Apple-500ml.png',
      group: 'REIGN',
    });
    skus.push({
      id: 104,
      name: 'SOUR GUMMY WORM',
      image:
        'assets/images/root-product/REIGN/SOUR GUMMY WORM/Default-Sour-Gummy-Worm.png',
      group: 'REIGN',
    });
    skus.push({
      id: 105,
      name: 'STRAWBERRY SUBLIME',
      image:
        'assets/images/root-product/REIGN/STRAWBERRY SUBLIME/DEFAULT-Strawberry-Sublime.png',
      group: 'REIGN',
    });
    skus.push({
      id: 106,
      name: 'TROPICAL STORM',
      image:
        'assets/images/root-product/REIGN/TROPICAL STORM/Default-Tropical-Storm.png',
      group: 'REIGN',
    });
    skus.push({
      id: 107,
      name: 'WHITE GUMMY BEAR',
      image:
        'assets/images/root-product/REIGN/WHITE GUMMY BEAR/DE-White-Gummy-Bear-500ml.png',
      group: 'REIGN',
    });
    skus.push({
      id: 108,
      name: 'WHITE KNIGHT',
      image:
        'assets/images/root-product/REIGN/WHITE KNIGHT/Default-White-Knight.png',
      group: 'REIGN',
    });
    skus.push({
      id: 109,
      name: 'KIWI',
      image:
        'assets/images/root-product/REIGN STORM/KIWI/Default-Kiwi-Blend.png',
      group: 'REIGN STORM',
    });
    skus.push({
      id: 110,
      name: 'PEACH NECTARINE',
      image:
        'assets/images/root-product/REIGN STORM/PEACH NECTARINE/Default-Peach-Nectarine.png',
      group: 'REIGN STORM',
    });
    skus.push({
      id: 111,
      name: 'VALENCIA ORANGE',
      image:
        'assets/images/root-product/REIGN STORM/VALENCIA ORANGE/Default-Valencia-Orange.png',
      group: 'REIGN STORM',
    });
    skus.push({
      id: 112,
      name: 'APPLE KIWI',
      image:
        'assets/images/root-product/RELENTLESS/APPLE KIWI/Default-Apple-Kiwi-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 113,
      name: 'CHERRY',
      image:
        'assets/images/root-product/RELENTLESS/CHERRY/Default-Cherry-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 114,
      name: 'FRUIT PUNCH',
      image:
        'assets/images/root-product/RELENTLESS/FRUIT PUNCH/Default-Fruit-Punch-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 115,
      name: 'LEMON ICE',
      image:
        'assets/images/root-product/RELENTLESS/LEMON ICE/Default-Lemon-Ice-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 116,
      name: 'MANGO',
      image:
        'assets/images/root-product/RELENTLESS/MANGO/Default-Mango-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 117,
      name: 'ORIGIN',
      image:
        'assets/images/root-product/RELENTLESS/ORIGIN/Default-Origin-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 118,
      name: 'PASSION PUNCH',
      image:
        'assets/images/root-product/RELENTLESS/PASSION PUNCH/Default-Passion-Punch-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 119,
      name: 'SOUR TWIST',
      image:
        'assets/images/root-product/RELENTLESS/SOUR TWIST/Default-Sour-Twist-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 120,
      name: 'ZERO PEACH',
      image:
        'assets/images/root-product/RELENTLESS/ZERO PEACH/Default-Zero-Peach-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 121,
      name: 'ZERO RASPBERRY',
      image:
        'assets/images/root-product/RELENTLESS/ZERO RASPBERRY/Default-Zero-Raspberry-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 122,
      name: 'ZERO WATERMELON',
      image:
        'assets/images/root-product/RELENTLESS/ZERO WATERMELON/Default-Zero-Watermelon-500ml.png',
      group: 'RELENTLESS',
    });
    skus.push({
      id: 123,
      name: 'BLACK',
      image:
        'assets/images/root-product/ULTRA/BLACK/Default-Ultra-Black-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 124,
      name: 'BLUE',
      image:
        'assets/images/root-product/ULTRA/BLUE/Default-Ultra-Blue-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 125,
      name: 'CITRON',
      image:
        'assets/images/root-product/ULTRA/CITRON/DE-Ultra-Citron-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 126,
      name: 'GOLD',
      image:
        'assets/images/root-product/ULTRA/GOLD/Default-Ultra-Golden-Pineapple-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 127,
      name: 'MANGO FIESTA',
      image:
        'assets/images/root-product/ULTRA/MANGO FIESTA/Default-Ultra-Mango-Fiesta-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 128,
      name: 'PARADISE',
      image:
        'assets/images/root-product/ULTRA/PARADISE/Default-Ultra-Paradise-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 129,
      name: 'PEACHY KEEN',
      image:
        'assets/images/root-product/ULTRA/PEACHY KEEN/Default-Ultra-Peachy-Keen-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 130,
      name: 'RED',
      image: 'assets/images/root-product/ULTRA/RED/Default-Ultra-Red-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 131,
      name: 'ROSA',
      image:
        'assets/images/root-product/ULTRA/ROSA/Default-Ultra-Rosa-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 132,
      name: 'STRAWBERRY',
      image:
        'assets/images/root-product/ULTRA/STRAWBERRY/Default-Ultra-Strawberry-Dreams-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 133,
      name: 'SUNRISE',
      image:
        'assets/images/root-product/ULTRA/SUNRISE/Default-Ultra-Sunsrise-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 134,
      name: 'VIOLET',
      image:
        'assets/images/root-product/ULTRA/VIOLET/Default-Ultra-Violet-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 135,
      name: 'WATERMELON',
      image:
        'assets/images/root-product/ULTRA/WATERMELON/Default-Ultra-Watermelon-500ml.png',
      group: 'ULTRA',
    });
    skus.push({
      id: 136,
      name: 'WHITE',
      image: 'assets/images/root-product/ULTRA/WHITE/AE-Ultra-White-250ml.png',
      group: 'ULTRA',
    });

    return skus;
  }
}

interface Sku {
  id: number;
  name: string;
  image: string;
  group: string;
}

interface SkuGroup {
  name: string;
  image: string;
}

interface GroupWithSkus {
  skuGroup: SkuGroup;
  skus: Sku[];
}
