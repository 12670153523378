<div class="flex items-center justify-items-center">
  <span
    class="border flex font-medium gap-2 items-center me-2 px-1.5 py-1 rounded-full text-xs"
    [ngClass]="{
      'bg-green-100 border-green-900 text-green-900': isOn,
      'bg-orange-100 border-orange-900 text-orange-900': !isOn,
    }"
  >
    <div
      class="w-3 h-3 bg-orange-500 rounded-full"
      [ngClass]="{
        'bg-green-500': isOn,
        'bg-orange-500': !isOn
      }"
    ></div>
    <span class="leading-none">{{ text }}</span>
  </span>
</div>
