import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderedItem } from './order-selector-modal/order-selector-modal.component';
import { OrderSignatureComponent } from './order-signature/order-signature.component';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface Product {
  productName: string;
  skuName: string;
  imagePath: string;
  groupName: string;
  price: number;
  default: boolean;
}

interface SkuGroup {
  name: string;
  image: string;
}

interface GroupWithSkus {
  skuGroup: SkuGroup;
  skus: Product[];
}

interface OrderItem {
  skuName: string;
  type: 'Single' | 'Multipack' | 'Seeding' | 'Free';
  price: number;
  quantity: number;
}

interface OrderModalData {
  productName: string;
  variants: Product[];
}

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
})
export class OrderPageComponent implements OnInit, OnDestroy {
  @Output() nextSurveyStepEvent = new EventEmitter<void>();
  @Output() previousSurveyStepEvent = new EventEmitter<void>();

  products: Product[] = [];
  groupedProducts: GroupWithSkus[] = [];

  showScrollButton = false;
  private scrollListener: any;

  selectedProduct?: OrderModalData;
  showOrderModal = false;
  totalOrderCount = 0;

  showOrderSummary = false;
  orderItems: OrderedItem[] = [];

  @Input() isWholesale = false;

  pages: string[] = ['Order', 'Summary', 'Signature', 'Receipt'];
  wholesalePages: string[] = ['Order', 'Summary', 'PhotoReceipt', 'Receipt'];
  currentPage: number = 0;

  signatureData: string = '';
  wholesaleSignatureData: string = '';

  @ViewChild(OrderSignatureComponent)
  signatureComponent!: OrderSignatureComponent;

  constructor(private http: HttpClient, private router: Router) {
    if (!environment.production) {
      const path = './assets/data/products.json';
      console.log('Attempting to load:', path);
      this.http.get(path).subscribe(
        (data) => console.log('Success:', data),
        (error) => console.error('Error:', error)
      );
    }
  }

  ngOnInit(): void {
    this.http.get<Product[]>('./assets/data/products.json').subscribe({
      next: (data) => {
        this.products = data;
        this.groupProducts();
        console.log(
          'Products loaded and grouped:',
          this.groupedProducts.length
        );
      },
      error: (error) => {
        console.error('Error loading products:', error);
      },
    });

    // Add scroll listener
    this.scrollListener = () => {
      this.showScrollButton = window.scrollY > 100; // Show after 100px of scroll
    };
    window.addEventListener('scroll', this.scrollListener);

    if (this.isWholesale) {
      this.pages = this.wholesalePages;
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollListener);
  }

  private groupProducts(): void {
    // Filter for default products first
    const defaultProducts = this.products.filter((product) => product.default);

    // Get unique group names from default products only
    const uniqueGroups = [
      ...new Set(defaultProducts.map((product) => product.groupName)),
    ];

    // Create grouped products
    this.groupedProducts = uniqueGroups.map((groupName) => {
      const groupProducts = defaultProducts.filter(
        (product) => product.groupName === groupName
      );

      return {
        skuGroup: {
          name: groupName,
          image: `assets/images/product-groups/${groupName.toLowerCase()}.png`,
        },
        skus: groupProducts,
      };
    });

    // Sort groups alphabetically
    this.groupedProducts.sort((a, b) =>
      a.skuGroup.name.localeCompare(b.skuGroup.name)
    );
  }

  scrollToGroup(group: SkuGroup) {
    const groupElement = document.getElementById(group.name);
    if (groupElement) {
      const headerOffset = 100;
      const elementPosition = groupElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openOrderModal(product: Product) {
    const variants = this.products.filter(
      (p) => p.skuName === product.skuName && p.groupName === product.groupName
    );
    // add one default product to the variants
    const defaultProduct = variants.find((p) => p.default);

    if (defaultProduct) {
      const freeProduct = { ...defaultProduct };
      freeProduct.price = 0;
      freeProduct.productName = product.productName + ' Free Can';
      variants.push(freeProduct);
    }

    this.selectedProduct = {
      productName: product.productName,
      variants: variants,
    };
    this.showOrderModal = true;
  }

  handleOrderConfirm(orderItems: OrderedItem[]) {
    // Handle order confirmation
    this.showOrderModal = false;

    //add, don't replace
    this.orderItems.push(...orderItems);
    this.totalOrderCount = this.orderItems.length;
  }

  async getSignature(): Promise<string> {
    return this.signatureComponent.getSignature();
  }

  handlePhotoReceiptConfirm(photoUrl: string | null) {
    this.wholesaleSignatureData = photoUrl || '';
  }

  async nextStep() {
    if (this.pages[this.currentPage] === 'Order') {
      if (this.totalOrderCount === 0) {
        Swal.fire({
          title: 'No items selected for order',
          text: !this.isWholesale
            ? 'Are you sure you want to continue to the next step of the survey?'
            : 'Go back to the call list?',
          icon: 'warning',
          confirmButtonText: '<i class="fas fa-check"></i> Confirm',
          cancelButtonText: 'Close',
          showCancelButton: true,
          customClass: {
            icon: 'no-border',
            confirmButton: 'btn btn-success bg-custom-green text-black',
            title: 'swal2-title-small',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            if (!this.isWholesale) {
              this.nextSurveyStep();
            } else {
              this.router.navigate(['/store-list']);
            }
          }
        });

        return;
      }
    }

    if (this.pages[this.currentPage] === 'Signature') {
      this.signatureData = await this.getSignature();
    }

    if (this.currentPage < this.pages.length - 1) {
      this.currentPage++;
    } else {
      if (!this.isWholesale) {
        this.nextSurveyStep();
      } else {
        this.backToCallList();
      }
      return;
    }
  }

  previousStep() {
    if (this.currentPage > 0) {
      this.currentPage--;
    } else {
      if (!this.isWholesale) {
        this.previousSurveyStep();
      } else {
        this.router.navigate(['/store-list']);
      }
    }
  }

  backToCallList() {
    Swal.fire({
      title: 'Wholesale order complete',
      icon: 'success',
      confirmButtonText: '<i class="fas fa-check"></i> Back to call list',
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-success bg-custom-green text-black',
        title: 'swal2-title-small',
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }

  nextSurveyStep() {
    this.nextSurveyStepEvent.emit();
  }

  previousSurveyStep() {
    this.previousSurveyStepEvent.emit();
  }
}
