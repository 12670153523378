
export class TokenHelper {

  private _decodedToken: string = "";
  private _tokenData: Array<{ [key: string]: object }> = new Array<{ [key: string]: object }>();

  private resetToken() {

    this._tokenData = new Array<{ [key: string]: object }>();
    this._decodedToken = "";
  }

  public getToken(): Array<{ [key: string]: object }> {

    this.resetToken();

    let encodedToken = localStorage.getItem("id_token") ?? "";

    if (encodedToken.length > 0) {

      this._decodedToken = window.atob(encodedToken);
      this._tokenData = JSON.parse(this._decodedToken);
    }

    return this._tokenData;
  }

  public saveToken() {

    const encodedToken = window.btoa(this._decodedToken);

    localStorage.setItem("id_token", encodedToken);
    this.getToken();
  }

  public getTokenVar(key: any) {

    if (this._tokenData.length > 0) {

      if (key in this._tokenData)
        return this._tokenData[key];
    }
    return "";
  }

  public setTokenVar(key: any, newValue: any) {

    this._tokenData[key] = newValue;
    this.saveToken();
  }

  public updateTokenVar(key: string, newValue: string) {

    const token = this.getToken();
    let tokenData = this._decodedToken;

    this.setTokenVar(key, newValue);
  }

  private tryParseToken(token: string) {

    let jsonToken = "";

    try {
      jsonToken = JSON.parse(token);
      return jsonToken;
    }
    catch {
      return "";
    }
  }

  public decodeToken(token: string) {

    let jsonToken = this.tryParseToken(token);
    let decodedJwtJsonData = "";
    const jwtData = jsonToken.split('.')[1];

    try {
      decodedJwtJsonData = window.atob(jwtData);
      return decodedJwtJsonData;
    }
    catch (error) {
      console.log("Cannot decode jwt. Error: " + error);
      return;
    }
  }

  public isExpired() {
    if (this._decodedToken.length == 0)
      return true;
    return true;
  }

  constructor() {

    this.getToken();
    if (this._decodedToken.length > 0) {
      let expiryDate: Date = new Date(<string>this.getTokenVar("expiry"));
    }
  }

}
