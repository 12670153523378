import { Component, Input } from '@angular/core';
import { OrderedItem } from '../order-selector-modal/order-selector-modal.component';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
})
export class OrderSummaryComponent {
  @Input() orderItems: OrderedItem[] = [];

  quantityOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
    (num) => ({
      label: num.toString(),
      value: num.toString(),
    })
  );

  removeItem(item: OrderedItem) {
    this.orderItems = this.orderItems.filter((i) => i !== item);
  }

  getTotal(): number {
    return this.orderItems.reduce(
      (acc, item) => acc + item.quantity * item.product.price,
      0
    );
  }

  handleQuantityChange(newValue: string, item: OrderedItem) {
    item.quantity = parseInt(newValue);
  }
}
