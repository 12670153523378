<div class="p-6 max-w-3xl mx-auto">
  <!-- Header -->
  <h2 class="text-3xl font-teko font-bold text-center mb-8">ORDER SUMMARY</h2>

  <!-- Main Order Section -->
  <div class="mb-8">
    <h3 class="ont-bold mb-4 font-teko uppercase">ORDER</h3>

    <!-- Order Items -->
    <div class="space-y-4">
      <div
        *ngFor="let item of orderItems"
        class="flex items-center border border-custom-green rounded-lg p-4"
      >
        <img
          [src]="item.product.imagePath"
          [alt]="item.product.productName"
          class="w-16 h-16 object-contain mr-4"
        />
        <div class="flex-grow">
          <h4 class="font-bold font-teko uppercase">
            {{ item.product.skuName }}
          </h4>
          <p class="text-gray-600 text-sm font-proxima">
            {{ item.product.productName }}
          </p>
          <button
            (click)="removeItem(item)"
            class="text-custom-green text-sm hover:text-custom-green/90 font-proxima"
          >
            Remove
          </button>
        </div>
        <app-dropdown
          [options]="quantityOptions"
          [value]="item.quantity.toString()"
          (valueChange)="handleQuantityChange($event, item)"
        ></app-dropdown>
      </div>
    </div>
  </div>

  <!-- Total Section -->
  <div class="pt-4">
    <div
      class="flex justify-between items-center mb-2 font-proxima border-b border-custom-green pb-2"
    >
      <span class="text-gray-600">VAT</span>
      <span>€2.00</span>
    </div>
    <div
      class="flex justify-between items-center text-xl font-bold font-proxima border-b border-custom-green pb-2"
    >
      <span>Total</span>
      <span class="font-teko">€{{ getTotal().toFixed(2) }}</span>
    </div>
  </div>
</div>
