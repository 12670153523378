import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '../order-page.component';

interface OrderModalData {
  productName: string;
  variants: Product[];
}

export interface OrderedItem {
  product: Product;
  quantity: number;
}

interface VariantWithQuantity extends Product {
  quantity: number;
}

@Component({
  selector: 'app-order-selector-modal',
  templateUrl: './order-selector-modal.component.html',
})
export class OrderSelectorModalComponent {
  @Input() data!: OrderModalData;
  @Output() closeModal = new EventEmitter<void>();
  @Output() confirmOrder = new EventEmitter<OrderedItem[]>();

  orderItems: OrderedItem[] = [];
  private quantityMap: Map<string, number> = new Map();

  getVariantType(productName: string): string {
    if (productName.includes('Multipack')) return 'Multipack';
    if (productName.includes('Mulitpack')) return 'Multipack';
    if (productName.includes('500ml')) return 'Single';
    if (productName.includes('Free')) return 'Free';
    return 'Single';
  }

  getFormattedProductName(productName: string, skuName: string): string {
    let formattedProductName = productName
      .toLowerCase()
      .includes(skuName.toLowerCase())
      ? productName.replace(new RegExp(skuName, 'i'), '').trim()
      : productName;
    if (formattedProductName === '') {
      return 'Single Can';
    }
    return (
      formattedProductName.charAt(0).toUpperCase() +
      formattedProductName.slice(1)
    );
  }

  addItemToCart(variant: Product) {
    const variantKey = `${variant.groupName}-${variant.skuName}-${variant.productName}`;

    const quantity = this.quantityMap.get(variantKey) || 1;

    this.orderItems = this.orderItems.filter(
      (item) =>
        !(
          item.product.skuName === variant.skuName &&
          item.product.groupName === variant.groupName &&
          item.product.productName === variant.productName
        )
    );

    this.orderItems.push({
      product: variant,
      quantity: quantity,
    });
  }

  itemInCart(variant: Product): boolean {
    // is variant in cart?
    return this.orderItems.some(
      (item) =>
        item.product.skuName === variant.skuName &&
        item.product.groupName === variant.groupName &&
        item.product.productName === variant.productName
    );
  }

  close() {
    this.closeModal.emit();
  }

  confirm() {
    this.confirmOrder.emit(this.orderItems);
    this.close();
  }

  handleQuantityChange(value: number, variant: Product) {
    const variantKey = `${variant.groupName}-${variant.skuName}-${variant.productName}`;
    this.quantityMap.set(variantKey, value);
  }

  getCurrentQuantity(variant: Product): number {
    const variantKey = `${variant.groupName}-${variant.skuName}-${variant.productName}`;
    return this.quantityMap.get(variantKey) || 1;
  }
}
