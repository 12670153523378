<footer
  class="w-full h-24 bg-white p-4 border-t border-gray-200 text-black dark:bg-zinc-900 dark:border-zinc-700 flex pin-b fixed inset-x-0 bottom-0"
>
  <div class="flex w-11/12 mx-auto justify-between p-2 font-teko text-xl">
    <button
      class="flex flex-col items-center dark:text-zinc-400"
      [routerLink]="['/store-list']"
    >
      <i
        class="fa-solid fa-store w-6 h-6"
        [ngClass]="{
          'text-custom-green': currentUrl === '/store-list',
          'text-gray-800 dark:text-zinc-400': currentUrl !== '/store-list'
        }"
      ></i>
      <span>Store List</span>
    </button>
    <button
      class="flex flex-col items-center dark:text-zinc-400"
      [routerLink]="['/wholesale']"
    >
      <i
        class="fa-solid fa-truck w-6 h-6"
        [ngClass]="{
          'text-custom-green': currentUrl === '/wholesale',
          'text-gray-800 dark:text-zinc-400': currentUrl !== '/wholesale'
        }"
      ></i>
      <span>Wholesale</span>
    </button>

    <button
      class="flex flex-col items-center dark:text-zinc-400"
      [routerLink]="['/feedback']"
    >
      <i
        class="fa-solid fa-pen-nib w-6 h-6"
        [ngClass]="{
          'text-custom-green': currentUrl === '/feedback',
          'text-gray-800 dark:text-zinc-400': currentUrl !== '/feedback'
        }"
      ></i>
      <span>Feedback</span>
    </button>
  </div>
</footer>
