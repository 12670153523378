import { Component, Input, Output, EventEmitter } from '@angular/core';

interface DropdownOption {
  value: string;
  label: string;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent {
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() options: DropdownOption[] = [];
  @Input() value: string = '';

  @Output() valueChange = new EventEmitter<string>();

  onChange(newValue: string) {
    this.value = newValue;
    this.valueChange.emit(newValue);
  }
}
