import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isLoggedIn = false;
  shouldPlayVideo: boolean = true;
  email: string | null = null;
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;

  constructor(private msalService: MsalService, private http: HttpClient) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.msalService.instance.initialize();
      const result = await this.msalService.instance.handleRedirectPromise();

      if (result && result.account) {
        console.log('Login successful via redirect. Account:', {
          username: result.account.username,
          environment: result.account.environment,
          tenantId: result.account.tenantId,
        });
        this.msalService.instance.setActiveAccount(result.account);
        this.isLoggedIn = true;
        this.getUserEmail();
      } else {
        const accounts = this.msalService.instance.getAllAccounts();
        this.isLoggedIn = accounts.length > 0;
        if (this.isLoggedIn) {
          console.log('User already logged in. Active account:', {
            username: accounts[0].username,
            environment: accounts[0].environment,
            tenantId: accounts[0].tenantId,
          });
          this.getUserEmail();
        } else {
          console.log('No active account found');
        }
      }
    } catch (error) {
      console.error(
        'Error during MSAL initialization or redirect handling:',
        error
      );
    }
  }

  ngAfterViewInit() {
    if (this.videoElement?.nativeElement) {
      const video = this.videoElement.nativeElement;
      video.muted = true;

      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => console.log('Video playing successfully'))
          .catch((error) => {
            console.log('Autoplay prevented:', error);
          });
      }
    }
  }

  login(): void {
    this.msalService.loginRedirect();
  }

  logout(): void {
    this.msalService.logoutRedirect();
  }

  private getUserEmail(): void {
    const account = this.msalService.instance.getActiveAccount();
    if (account) {
      console.log('Fetching email for account:', account.username);
      this.msalService.instance
        .acquireTokenSilent({
          scopes: ['User.Read'],
          account: account,
        })
        .then((response) => {
          console.log('Token acquired successfully for:', account.username);
          this.http
            .get('https://graph.microsoft.com/v1.0/me', {
              headers: {
                Authorization: `Bearer ${response.accessToken}`,
              },
            })
            .subscribe(
              (profile: any) => {
                this.email = profile.mail || profile.userPrincipalName;
              },
              (error) => {
                console.error('Error fetching user profile:', error);
              }
            );
        })
        .catch((error) => {
          console.error('Error acquiring token silently:', error);
        });
    }
  }
}
