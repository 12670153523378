<!-- Overlay Background -->
<div *ngIf="leftSidebar" class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50" style="backdrop-filter: blur(3px);" (click)="closeModal()">

    <!-- Left Sidebar -->
    <div class="h-full w-64 fixed left-0 bg-white dark:bg-zinc-900 p-4" (click)="$event.stopPropagation()">

        <!-- Home Button -->
        <button class="flex items-center justify-center text-black dark:text-white p-2 font-teko text-xl">
            <svg class="w-5 h-5 mr-2 text-gray-800 dark:text-zinc-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                <path fill-rule="evenodd" d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z" clip-rule="evenodd"/>
            </svg> 
            <span class="mt-1">Home</span>
        </button>

        <!-- Border To Daily Task Options -->
        <div class="border-b border-gray-200 dark:border-zinc-700 my-4"></div>

        <!-- Stores Button and Sub Nav Items -->
        <button class="flex items-center justify-between text-black dark:text-white p-2 font-teko text-xl" (click)="storeOptions = !storeOptions">
            <div class="w-48 flex items-center">
                <svg class="w-5 h-5 mr-2 text-gray-800 dark:text-zinc-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M5.535 7.677c.313-.98.687-2.023.926-2.677H17.46c.253.63.646 1.64.977 2.61.166.487.312.953.416 1.347.11.42.148.675.148.779 0 .18-.032.355-.09.515-.06.161-.144.3-.243.412-.1.111-.21.192-.324.245a.809.809 0 0 1-.686 0 1.004 1.004 0 0 1-.324-.245c-.1-.112-.183-.25-.242-.412a1.473 1.473 0 0 1-.091-.515 1 1 0 1 0-2 0 1.4 1.4 0 0 1-.333.927.896.896 0 0 1-.667.323.896.896 0 0 1-.667-.323A1.401 1.401 0 0 1 13 9.736a1 1 0 1 0-2 0 1.4 1.4 0 0 1-.333.927.896.896 0 0 1-.667.323.896.896 0 0 1-.667-.323A1.4 1.4 0 0 1 9 9.74v-.008a1 1 0 0 0-2 .003v.008a1.504 1.504 0 0 1-.18.712 1.22 1.22 0 0 1-.146.209l-.007.007a1.01 1.01 0 0 1-.325.248.82.82 0 0 1-.316.08.973.973 0 0 1-.563-.256 1.224 1.224 0 0 1-.102-.103A1.518 1.518 0 0 1 5 9.724v-.006a2.543 2.543 0 0 1 .029-.207c.024-.132.06-.296.11-.49.098-.385.237-.85.395-1.344ZM4 12.112a3.521 3.521 0 0 1-1-2.376c0-.349.098-.8.202-1.208.112-.441.264-.95.428-1.46.327-1.024.715-2.104.958-2.767A1.985 1.985 0 0 1 6.456 3h11.01c.803 0 1.539.481 1.844 1.243.258.641.67 1.697 1.019 2.72a22.3 22.3 0 0 1 .457 1.487c.114.433.214.903.214 1.286 0 .412-.072.821-.214 1.207A3.288 3.288 0 0 1 20 12.16V19a2 2 0 0 1-2 2h-6a1 1 0 0 1-1-1v-4H8v4a1 1 0 0 1-1 1H6a2 2 0 0 1-2-2v-6.888ZM13 15a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2Z" clip-rule="evenodd"/>
                </svg> 
                <span class="mt-1">Stores</span>
            </div>
                <svg [ngClass]="{'rotate-180': storeOptions}" class="w-5 h-5 text-gray-800 dark:text-zinc-400 transition-transform duration-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-3-3a1 1 0 011.414-1.414L10 9.586l2.293-2.293a1 1 0 011.414 1.414l-3 3A1 1 0 0110 12z" clip-rule="evenodd" />
                </svg>
        </button>     
        <ul *ngIf="storeOptions" class="ml-8 dark:text-white font-proxima text-sm">
            <li class="p-2">Option</li>
            <li class="p-2">Option</li>
            <li class="p-2">Option</li>
        </ul>

        <!-- Call List Button and Sub Nav Items -->
        <button class="flex items-center justify-between text-black dark:text-white p-2 font-teko text-xl" (click)="callsOptions = !callsOptions">
            <div class="w-48 flex items-center">
                <svg class="w-5 h-5 mr-2 text-gray-800 dark:text-zinc-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6h8m-8 6h8m-8 6h8M4 16a2 2 0 1 1 3.321 1.5L4 20h5M4 5l2-1v6m-2 0h4"/>
                </svg> 
                <span class="mt-1">Call List</span>
            </div>
                <svg [ngClass]="{'rotate-180': callsOptions}" class="w-5 h-5 text-gray-800 dark:text-zinc-400 transition-transform duration-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-3-3a1 1 0 011.414-1.414L10 9.586l2.293-2.293a1 1 0 011.414 1.414l-3 3A1 1 0 0110 12z" clip-rule="evenodd" />
                </svg>
        </button>   
        <ul *ngIf="callsOptions" class="ml-8 dark:text-white font-proxima text-sm">
            <li class="p-2">Option</li>
            <li class="p-2">Option</li>
            <li class="p-2">Option</li>
        </ul>

        <!-- Insights Button and Sub Nav Items -->
        <button class="flex items-center justify-between text-black dark:text-white p-2 font-teko text-xl" (click)="insightsOptions = !insightsOptions">
            <div class="w-48 flex items-center">
                <svg class="w-5 h-5 mr-2 text-gray-800 dark:text-zinc-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 15v4m6-6v6m6-4v4m6-6v6M3 11l6-5 6 5 5.5-5.5"/>
                </svg>                   
                <span class="mt-1">Insights</span>
            </div>
                <svg [ngClass]="{'rotate-180': insightsOptions}" class="w-5 h-5 text-gray-800 dark:text-zinc-400 transition-transform duration-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-3-3a1 1 0 011.414-1.414L10 9.586l2.293-2.293a1 1 0 011.414 1.414l-3 3A1 1 0 0110 12z" clip-rule="evenodd" />
                </svg>
        </button>   
        <ul *ngIf="insightsOptions" class="ml-8 dark:text-white font-proxima text-sm">
            <li class="p-2">Option</li>
            <li class="p-2">Option</li>
            <li class="p-2">Option</li>
        </ul>

        <!-- Border To Other Options -->
        <div class="border-b border-gray-200 dark:border-zinc-700 my-4"></div>

        <!-- Leaderboard Button -->
        <button class="flex items-center justify-between text-black dark:text-white p-2 font-teko text-xl">
            <div class="w-48 flex items-center">
                <svg class="w-5 h-5 mr-2 text-gray-800 dark:text-zinc-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z"/>
                </svg>                                   
                <span class="mt-1">Leaderboard</span>
            </div>
        </button>  
        
        <!-- Selling Tools Button -->
        <button class="flex items-center justify-between text-black dark:text-white p-2 font-teko text-xl">
            <div class="w-48 flex items-center">
                <svg class="w-5 h-5 mr-2 text-gray-800 dark:text-zinc-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Z" clip-rule="evenodd"/>
                </svg>                                     
                <span class="mt-1">Selling Tools</span>
            </div>
        </button>  

    </div>

</div>