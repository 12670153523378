<section class="bg-gray-50 min-h-screen flex flex-col dark:bg-gray-900">
  <div class="flex-grow mx-auto max-w-screen-xl p-2 w-full">
    <div
      class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden border border-gray-300 dark:border-gray-600 h-[calc(100vh-5rem)] flex flex-col"
    >
      <!-- Header -->
      <div class="flex justify-center pt-16">
        <h2
          class="text-5xl font-teko font-bold text-black dark:text-white uppercase"
        >
          SELECT TYPE OF DAY
        </h2>
      </div>

      <!-- Day Type Options -->
      <div class="flex-grow flex flex-col p-6 md:pb-0">
        <div class="space-y-4 max-w-md mx-auto w-full">
          <!-- TDM Day Option -->
          <div
            class="flex items-center p-4 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
            (click)="selectDayType('tdm')"
          >
            <label class="relative inline-flex items-center cursor-pointer">
              <input
                type="radio"
                [checked]="selectedType === 'tdm'"
                class="sr-only peer"
                name="dayType"
              />
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-custom-green/50 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-custom-green"
              ></div>
            </label>
            <div class="ml-4">
              <h3
                class="text-lg font-proxima font-medium text-gray-900 dark:text-white"
              >
                TDM Day in Trade
              </h3>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Select if today is a Normal day.
              </p>
            </div>
          </div>

          <!-- Bottler Day Option -->
          <div
            class="flex items-center p-4 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
            (click)="selectDayType('bottler')"
          >
            <label class="relative inline-flex items-center cursor-pointer">
              <input
                type="radio"
                [checked]="selectedType === 'bottler'"
                class="sr-only peer"
                name="dayType"
              />
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-custom-green/50 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-custom-green"
              ></div>
            </label>
            <div class="ml-4">
              <h3
                class="text-lg font-proxima font-medium text-gray-900 dark:text-white"
              >
                Bottler Day in Trade (DIT)
              </h3>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Select if today is Bottler Day.
              </p>
            </div>
          </div>

          <!-- Trade Partner Option -->
          <div
            class="flex items-center p-4 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
            (click)="selectDayType('partner')"
          >
            <label class="relative inline-flex items-center cursor-pointer">
              <input
                type="radio"
                [checked]="selectedType === 'partner'"
                class="sr-only peer"
                name="dayType"
              />
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-custom-green/50 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-custom-green"
              ></div>
            </label>
            <div class="ml-4">
              <h3
                class="text-lg font-proxima font-medium text-gray-900 dark:text-white"
              >
                Trade Partner Day in Trade
              </h3>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Select if today is Trade Partner.
              </p>
            </div>
          </div>

          <!-- RFM Option -->
          <div
            class="flex items-center p-4 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
            (click)="selectDayType('rfm')"
          >
            <label class="relative inline-flex items-center cursor-pointer">
              <input
                type="radio"
                [checked]="selectedType === 'rfm'"
                class="sr-only peer"
                name="dayType"
              />
              <div
                class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-custom-green/50 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-custom-green"
              ></div>
            </label>
            <div class="ml-4">
              <h3
                class="text-lg font-proxima font-medium text-gray-900 dark:text-white"
              >
                Regional Field Manager Day in Trade
              </h3>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Select if today is with an RFM.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end p-4">
        <button
          class="flex items-center justify-center px-5 py-2.5 gap-2 rounded-lg border border-custom-green bg-custom-green dark:bg-custom-green-dark text-black font-proxima text-sm font-bold hover:bg-custom-green-light dark:hover:bg-custom-green"
          (click)="onEnter()"
        >
          <span>Enter</span>
          <i class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</section>
