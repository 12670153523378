import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-photo-upload',
  templateUrl: './photo-upload.component.html',
})
export class PhotoUploadComponent {
  @Input() label?: string;
  @Input() coverImageSelector: boolean = false;
  @Input() maxFiles: number = 3;

  @Output()
  onConfirm = new EventEmitter<string | null>();

  selectedFiles: { file: File; url: string }[] = [];
  uploadedFiles: any[] = [];
  coverImageIndex: number = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    const newFiles = Array.from(files).map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    this.selectedFiles = [...this.selectedFiles, ...newFiles];

    if (this.selectedFiles.length === newFiles.length) {
      this.coverImageIndex = 0;
    }

    this.cdr.detectChanges();

    setTimeout(() => {
      this.scrollToBottom();
    }, 100);

    // convert to base64 string and emit
    const file = this.selectedFiles[this.coverImageIndex].file;
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      this.onConfirm.emit(base64String);
    };
    reader.readAsDataURL(file);
  }

  removeFile(index: number): void {
    const fileObj = this.selectedFiles[index];
    URL.revokeObjectURL(fileObj.url);
    this.selectedFiles.splice(index, 1);

    if (index <= this.coverImageIndex) {
      this.coverImageIndex = Math.min(
        this.coverImageIndex,
        this.selectedFiles.length - 1
      );
    }

    this.cdr.detectChanges();
  }

  setCoverImage(index: number): void {
    this.coverImageIndex = index;
  }

  ngOnDestroy() {
    this.selectedFiles.forEach((fileObj) => URL.revokeObjectURL(fileObj.url));
  }

  @ViewChild('fileList') fileListElement!: ElementRef;

  private scrollToBottom(): void {
    if (this.fileListElement) {
      const element = this.fileListElement.nativeElement;
      const rect = element.getBoundingClientRect();
      const scrollPosition = window.pageYOffset + rect.bottom + 200;

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  }
}
