<div class="p-6 max-w-3xl mx-auto">
  <!-- Logo -->
  <div class="flex justify-center mb-8">
    <img
      src="assets/images/monster-energy.png"
      alt="Monster Energy"
      class="h-24"
    />
  </div>

  <!-- Action Buttons -->
  <div class="flex justify-center gap-4 mb-8">
    <button
      (click)="print()"
      class="flex items-center gap-2 px-3 py-2 bg-gray-900 text-white rounded-lg text-xs font-proxima"
    >
      <i class="fa-solid fa-print"></i>
      Print
    </button>
    <button
      (click)="email()"
      class="flex items-center gap-2 px-3 py-2 bg-gray-900 text-white rounded-lg text-xs font-proxima"
    >
      <i class="fa-solid fa-envelope"></i>
      Email
    </button>
  </div>

  <!-- Receipt Content -->
  <div class="mb-8">
    <h2 class="text-gray-500 uppercase mb-4 font-teko">
      RECEIPT / SUMMARY (NO PRICES)
    </h2>

    <!-- Items -->
    <div class="space-y-4 mb-8">
      <div
        *ngFor="let item of orderItems"
        class="flex items-center justify-between py-4 border-b"
      >
        <div class="flex items-center gap-4">
          <img
            [src]="item.product.imagePath"
            [alt]="item.product.productName"
            class="w-12 h-16 object-contain"
          />
          <span class="text-gray-800">{{ item.product.productName }}</span>
        </div>
        <span class="text-gray-600">Qty: {{ item.quantity }}</span>
      </div>
    </div>
  </div>

  <!-- Signature Section -->
  <div *ngIf="photoData">
    <div *ngIf="!isWholesale">
      <h2 class="text-gray-500 uppercase mb-4 font-teko">SIGNATURE</h2>
      <div class="border-b pb-4">
        <img [src]="photoData" alt="Signature" class="max-h-32" />
      </div>
    </div>

    <div *ngIf="isWholesale">
      <h2 class="text-gray-500 uppercase mb-4 font-teko">PHOTO RECEIPT</h2>
      <div class="border-b pb-4">
        <img [src]="photoData" alt="Photo Receipt" class="max-h-32" />
      </div>
    </div>
  </div>
</div>
