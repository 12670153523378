<swal #modalContent [showConfirmButton]="false" [showCloseButton]="true">
  <div *swalPortal="swalTargets.content">
    <h2 class="text-left font-teko text-2xl font-bold mb-2">
      MONSTER CHILLER LOCATION
    </h2>
    <hr class="border-gray-200 dark:border-gray-700" />
    <div class="flex flex-col space-y-4 mt-4">
      <app-dropdown
        [options]="locationOptions"
        [name]="'Location In Store'"
        [label]="'Location In Store'"
        [value]="response.storeLocation || 'Front of Store'"
      ></app-dropdown>

      <div class="flex items-center mt-4">
        <label class="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            class="sr-only peer"
            name="compliant"
            [(ngModel)]="response.compliant"
          />
          <div
            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-custom-green/50 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-custom-green"
          ></div>
        </label>
        <div class="ml-4">
          <h3
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start"
          >
            Compliant?
          </h3>
        </div>
      </div>

      <div class="flex w-full gap-3 mt-4">
        <button
          class="flex-1 flex items-center justify-center px-5 py-2.5 gap-2 rounded-lg border border-custom-green bg-custom-green text-black font-proxima text-sm font-bold hover:bg-custom-green-light dark:hover:bg-custom-green"
          (click)="confirm()"
        >
          Confirm Location in Store
        </button>
        <button
          class="flex-1 flex items-center justify-center px-5 py-2.5 gap-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-black dark:text-white font-proxima text-sm font-bold hover:bg-gray-50 dark:hover:bg-gray-700"
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</swal>
