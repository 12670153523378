import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { SwalPortalTargets, SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-location-discontinue-modal',
  templateUrl: './location-discontinue-modal.component.html',
})
export class LocationDiscontinueModalComponent {
  @ViewChild('modalContent')
  modalContent!: SwalComponent;

  @Input() locationName: string = '';
  @Input() image: string = '';
  @Input() locationIndex: number = -1;

  @Output() discontinueEvent = new EventEmitter<number>();

  constructor(public readonly swalTargets: SwalPortalTargets) {}

  openModal() {
    this.modalContent.fire();
  }

  closeModal() {
    this.modalContent.close();
  }

  discontinueLocation() {
    this.discontinueEvent.emit(this.locationIndex);
  }
}
