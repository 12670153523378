<div
  class="w-[300px] h-[347px] bg-custom-green/10 dark:bg-gray-800 rounded-lg border-2 border-gray-200 dark:border-gray-700 transition-colors cursor-pointer flex flex-col"
>
  <div
    *ngIf="locationResponse?.locationId === 0; else locationAdded"
    class="w-full h-full flex flex-col items-center justify-center"
  >
    <h3
      class="text-4xl font-teko font-bold text-gray-900 dark:text-white uppercase text-center"
    >
      Add Location
    </h3>

    <div class="w-full flex justify-center mt-4">
      <div
        class="w-12 h-12 bg-custom-green/20 dark:bg-custom-green/20 rounded-full flex items-center justify-center"
      >
        <i
          class="fa-solid fa-plus text-gray-500 dark:text-gray-400 text-xl translate-y-[1px]"
        ></i>
      </div>
    </div>
  </div>

  <ng-template #locationAdded>
    <div class="w-full h-full flex flex-col">
      <!-- Location Image -->
      <div class="w-full h-[75%] overflow-hidden relative">
        <img
          *ngIf="locationResponse?.photo"
          [src]="locationResponse!.photo"
          alt="Location Photo"
          class="w-full h-full object-cover"
        />
        <div
          *ngIf="distributionType === 'Entry'"
          class="absolute top-2 right-1"
        >
          <app-on-off-badge
            [text]="'On Entry'"
            [isOn]="true"
          ></app-on-off-badge>
        </div>
        <div *ngIf="distributionType === 'Exit'" class="absolute top-2 right-1">
          <app-on-off-badge
            [text]="locationResponse?.completed ? 'Completed' : 'Uncompleted'"
            [isOn]="locationResponse?.completed ?? false"
          ></app-on-off-badge>
        </div>
      </div>
      <!-- Location Info -->
      <div class="flex flex-col p-4">
        <div class="">
          <div class="flex justify-between items-center">
            <h3 class="text-2xl font-teko font-bold">
              {{ locationResponse?.location }}
            </h3>
          </div>
        </div>
        <div class="">
          <p class="text-sm text-gray-500 dark:text-gray-400">
            {{ locationResponse?.storeLocation }}
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</div>
