import { Injectable } from "@angular/core";
import { UserModel } from "../Models/UserModel";
import { TokenHelper } from "./TokenHelper";



@Injectable()
export class AppSettings {

  private _tokenHelper: TokenHelper = new TokenHelper();
  private _user: UserModel = new UserModel("");
  private _rawToken: string = "";


  public get User(): UserModel { return this._user; }
  public set User(user: UserModel) { this._user = user; }

  public get TokenHelper() { return this._tokenHelper; }
  public set TokenHelper(tokenHelper: TokenHelper) { this._tokenHelper = tokenHelper; }


  constructor() {

    let tkn = localStorage.getItem("userToken");

    if (tkn) {
      this._rawToken = tkn;

      this._user = new UserModel(this._rawToken);
    }
  }
}
