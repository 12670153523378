<!-- Overlay Background -->
<div *ngIf="notificationSidebar" class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50" style="backdrop-filter: blur(3px);" (click)="closeModal()">

    <!-- Left Sidebar -->
    <div class="h-full w-64 fixed right-0 bg-white dark:bg-zinc-900 p-4" (click)="$event.stopPropagation()">

        <div class="p-2 dark:text-white font-teko text-2xl">Notifications</div>

        <!-- Border To Daily Task Options -->
        <div class="border-b border-gray-200 dark:border-zinc-700"></div>

    </div>

</div>
