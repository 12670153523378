import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-bottom-nav-bar',
  templateUrl: './bottom-nav-bar.component.html',
})
export class BottomNavBarComponent {
  @Input() show: boolean = true;
  @Input() leftButtonText!: string;
  @Input() rightButtonText!: string;
  @Input() leftButtonIcon!: string;
  @Input() rightButtonIcon!: string;

  @Output() leftButtonClickEvent = new EventEmitter<void>();
  @Output() rightButtonClickEvent = new EventEmitter<void>();

  leftButtonClick() {
    this.leftButtonClickEvent.emit();
  }

  rightButtonClick() {
    this.rightButtonClickEvent.emit();
  }
}
