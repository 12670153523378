import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {

  private _darkMode = new BehaviorSubject<boolean>(false);
  public currentDarkMode = this._darkMode.asObservable();

  toggleDarkMode() {
    this._darkMode.next(!this._darkMode.value);
  }

}
