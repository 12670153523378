import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/_shared/services/toast.service';
import {
  StoreNotesInputModel,
  StoresSummaryService,
} from 'src/app/services/swagger.gen';

@Component({
  selector: 'app-add-store-note',
  templateUrl: './add-store-note.component.html',
})
export class AddStoreNoteComponent {
  private destroy$ = new Subject<void>();
  private isSubmitting = false;

  @ViewChild('modalContent')
  modalContent!: SwalComponent;

  note: string = '';

  @Input()
  storeId: number = 0;

  @Output()
  storeUpdated = new EventEmitter<void>();

  constructor(
    private storesService: StoresSummaryService,
    private toastService: ToastService,
    public readonly swalTargets: SwalPortalTargets
  ) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  open() {
    this.modalContent.fire();
  }

  onSubmit() {
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    let storeNote = new StoreNotesInputModel();
    storeNote.note = this.note;
    storeNote.storeId = this.storeId;
    this.storesService
      .addStoreNote(storeNote)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.dismiss();
          setTimeout(() => {
            this.toastService.success('Store note added successfully');
            this.storeUpdated.emit();
          }, 100);
          this.isSubmitting = false;
        },
        error: () => {
          this.toastService.error('Error adding store note');
          this.isSubmitting = false;
        },
      });
  }

  dismiss() {
    this.modalContent.close();
    this.note = '';
  }
}
