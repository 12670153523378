import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  QuestionOptionsUIModel,
  QuestionsUIModel,
} from 'src/app/services/swagger.gen';
import Swal from 'sweetalert2';
import { LocationResponse } from '../../survey-page/survey-page.component';
import { LocationDiscontinueModalComponent } from './location-discontinue-modal.component';
@Component({
  selector: 'app-distribution-repeater',
  templateUrl: './distribution-repeater.component.html',
})
export class DistributionRepeaterComponent {
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() label: string = '';
  @Input() questions: QuestionsUIModel[] = [];
  @Input() locationResponses!: LocationResponse[];

  @Output() nextSurveyStepEvent = new EventEmitter<void>();
  @Output() previousSurveyStepEvent = new EventEmitter<void>();

  @ViewChild(LocationDiscontinueModalComponent)
  locationDiscontinueModal!: LocationDiscontinueModalComponent;

  currentQuestion: QuestionsUIModel | null = null;
  distributionType: 'Entry' | 'Exit' = 'Entry';

  currentLocationResponse!: LocationResponse;

  page: number = -1;

  ngOnInit() {
    this.distributionType = this.name.toLowerCase().includes('entry')
      ? 'Entry'
      : 'Exit';

    this.resetCurrentLocationResponse();
  }

  resetCurrentLocationResponse() {
    this.currentLocationResponse = {
      locationId: 0,
      photo: null,
      compliant: false,
      location: '',
      storeLocation: '',
      skus: [],
      completed: this.distributionType == 'Exit',
      createdAt: this.distributionType,
    };
  }

  addLocation() {
    this.page = 0;
  }

  clickLocation(location: LocationResponse) {
    if (this.distributionType === 'Entry') {
      this.locationDiscontinueModal.locationName = location.location;
      this.locationDiscontinueModal.image = location.photo ?? '';
      this.locationDiscontinueModal.locationIndex =
        this.locationResponses.indexOf(location);
      this.locationDiscontinueModal.openModal();
    }
    if (this.distributionType === 'Exit') {
      this.currentLocationResponse = location;
      this.page = 0;
    }
  }

  locationDiscontinued(locationIndex: number) {
    this.locationDiscontinueModal.closeModal();
    this.locationResponses.splice(locationIndex, 1);
  }

  nextStep() {
    if (this.page === -1) {
      this.nextSurveyStep();
      return;
    }

    this.page++;
    if (this.page < this.questions.length) {
      this.currentQuestion = this.questions[this.page];
    } else {
      this.currentQuestion = null;

      if (this.distributionType === 'Entry') {
        this.locationResponses.unshift({ ...this.currentLocationResponse });
      } else {
        // if current location is not in locationResponses, add it
        if (
          !this.locationResponses.some(
            (location) => location === this.currentLocationResponse
          )
        ) {
          this.locationResponses.push({ ...this.currentLocationResponse });
        }

        this.currentLocationResponse.completed = true;
      }

      this.page = -1;
      this.resetCurrentLocationResponse();
    }

    this.locationResponses;
  }

  async nextSurveyStep() {
    // If locations is empty, show confirmation modal
    if (this.locationResponses.length === 0) {
      const result = await Swal.fire({
        title: 'No In Store Locations Added',
        text: 'Are you sure you want to proceed?',
        icon: 'warning',
        confirmButtonText: '<i class="fas fa-check"></i> Confirm',
        showCancelButton: true,
        cancelButtonText: 'Close',
        customClass: {
          icon: 'no-border',
          confirmButton: 'btn btn-success bg-custom-green text-black',
          title: 'swal2-title-small',
        },
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    if (this.distributionType === 'Exit') {
      if (!this.locationResponses.every((location) => location.completed)) {
        const result = await Swal.fire({
          title: 'Incomplete Locations',
          text: 'Are you sure you want to proceed?',
          icon: 'warning',
          confirmButtonText: '<i class="fas fa-check"></i> Confirm',
          showCancelButton: true,
          cancelButtonText: 'Close',
          customClass: {
            icon: 'no-border',
            confirmButton: 'btn btn-success bg-custom-green text-black',
            title: 'swal2-title-small',
          },
        });

        if (!result.isConfirmed) {
          return;
        }
      }
    }

    this.nextSurveyStepEvent.emit();
  }

  photoUploaded(photo: string | null) {
    this.currentLocationResponse.photo = photo;
  }

  previousStep() {
    if (this.page === -1) {
      this.previousSurveyStepEvent.emit();
      return;
    }

    this.page--;
    if (this.page == -1) {
      this.resetCurrentLocationResponse();
    }
    this.currentQuestion = this.questions[this.page];
  }
}
