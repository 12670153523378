<div class="p-6 max-w-3xl mx-auto">
  <!-- Header -->
  <h2 class="text-3xl font-teko font-bold text-center mb-4">SIGNATURE</h2>

  <!-- Instruction -->
  <p class="text-center text-gray-600 mb-8 font-proxima">
    Collect the signature of the person placing the Order.
  </p>

  <!-- Signature Canvas Container -->
  <p class="pt-4 text-left text-black mb-2 font-teko font-bold">Signature</p>
  <div class="mb-8">
    <div class="signature-container relative bg-gray-100" style="height: 200px">
      <canvas
        #signatureCanvas
        class="w-full h-full bg-gray-100 border border-gray-200 border-dotted rounded"
        style="touch-action: none"
      >
      </canvas>

      <!-- Clear button - absolute positioned top right -->
      <button
        (click)="clearSignature()"
        class="absolute top-2 right-2 bg-white rounded-full p-2 shadow-sm hover:bg-gray-50"
        *ngIf="!isEmpty"
      >
        <i class="fa-solid fa-xmark text-gray-500"></i>
      </button>
    </div>
  </div>
</div>
