<div *ngIf="showLocations" class="flex flex-col space-y-4">
  <div class="grid grid-cols-3 gap-4">
    <div
      *ngFor="let option of currentOptions"
      [ngClass]="{
        'col-span-1': option.componentName === 'SingleImageSelector'
      }"
    >
      <app-single-image-selector
        *ngIf="option.componentName === 'SingleImageSelector'"
        [type]="previousDescription"
        [title]="option.description!"
        [image]="'/assets/images' + option.imageUrl"
        (click)="selectOption(option)"
        [class.selected]="option === currentOption"
      ></app-single-image-selector>
    </div>
  </div>
</div>

<app-bottom-nav-bar
  [leftButtonText]="'Back'"
  [leftButtonIcon]="'fa-solid fa-arrow-left'"
  (leftButtonClickEvent)="goBack()"
></app-bottom-nav-bar>

<app-location-selector-modal
  [response]="response"
  (onConfirm)="onModalConfirm()"
  (onCancel)="onModalCancel()"
></app-location-selector-modal>
