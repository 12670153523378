<div class="container mx-auto mt-2 px-4 bg-white dark:bg-gray-800 rounded-lg shadow-md pb-36">
    <h2 class="text-5xl font-teko font-bold text-black dark:text-white font-weight-700 p-8 uppercase text-center">
        End Store Visit
    </h2>
    <h5 class="text-xl font-proxima text-black dark:text-white pb-8 text-center">
        You are about to complete the store visit.
    </h5>
    <label
        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    >Add Note</label>
    <textarea
          id="note"
          name="note"
          class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          style="height: 200px"
          placeholder="Write store note here..."
          [(ngModel)]="note"
    ></textarea>
</div>

<app-bottom-nav-bar
    [rightButtonText]="'Finish'"
    [rightButtonIcon]="'fa-solid fa-arrow-right'"
    (rightButtonClickEvent)="finish()"
></app-bottom-nav-bar>