<div class="form-group w-full">
  <label
    [for]="id"
    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    >{{ label }}</label
  >
  <div class="relative flex items-center w-full">
    <button
      type="button"
      (click)="decrement()"
      class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none touch-none flex items-center justify-center"
    >
      <i class="fas fa-minus w-3 h-3 text-gray-900 dark:text-white"></i>
    </button>
    <input
      type="text"
      [id]="id"
      [name]="name"
      [(ngModel)]="value"
      class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-custom-green focus:border-custom-green block flex-1 min-w-0 w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-custom-green dark:focus:border-custom-green"
      [attr.aria-describedby]="id + '-explanation'"
      pattern="\d*"
      inputmode="numeric"
      required
    />
    <button
      type="button"
      (click)="increment()"
      class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none touch-none flex items-center justify-center"
    >
      <i class="fas fa-plus w-3 h-3 text-gray-900 dark:text-white"></i>
    </button>
  </div>
</div>
