import { Injectable, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';

export enum ToastType {
  Success,
  Error,
  Warning,
  Info,
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toastEvent = new EventEmitter<[ToastType, string]>();

  constructor() {
    this.toastEvent.subscribe(([type, message]) =>
      this.showToast(type, message)
    );
  }

  success(message: string) {
    this.toastEvent.emit([ToastType.Success, message]);
  }

  error(error: string | Error) {
    const message = error instanceof Error ? error.message : error;
    this.toastEvent.emit([ToastType.Error, message]);
  }

  private showToast(type: ToastType, message: string) {
    Swal.fire({
      icon: this.getIcon(type),
      title: this.getTitle(type),
      text: message,
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  }

  private getIcon(type: ToastType): 'success' | 'error' | 'warning' | 'info' {
    switch (type) {
      case ToastType.Success:
        return 'success';
      case ToastType.Error:
        return 'error';
      case ToastType.Warning:
        return 'warning';
      case ToastType.Info:
        return 'info';
    }
  }

  private getTitle(type: ToastType): string {
    switch (type) {
      case ToastType.Success:
        return 'Success';
      case ToastType.Error:
        return 'Error';
      case ToastType.Warning:
        return 'Warning';
      case ToastType.Info:
        return 'Information';
    }
  }
}
