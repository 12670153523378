import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeIcon',
})
export class RemoveIconPipe implements PipeTransform {
  transform(storeId: number, removedStores: number[]): string {
    return removedStores.includes(storeId)
      ? 'fa-solid fa-eye-slash'
      : 'fa-solid fa-eye';
  }
}
