<swal #modalContent [showConfirmButton]="false" [showCloseButton]="true">
  <div *swalPortal="swalTargets.content">
    <div class="max-w-md mx-auto">
      <!-- Modal header -->
      <h3
        class="text-xl font-semibold font-teko text-gray-900 mb-4 uppercase text-left"
      >
        {{ locationName }} LOCATION
      </h3>
      <hr class="border-gray-200 dark:border-gray-700" />
      <!-- Modal body -->
      <div class="my-6">
        <img [src]="image" alt="Location Image" class="w-full h-auto" />
      </div>

      <!-- Modal footer -->
      <div class="flex justify-between gap-4">
        <button
          (click)="closeModal()"
          type="button"
          class="w-full px-5 py-2.5 text-sm font-medium bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200"
        >
          Close
        </button>
        <button
          (click)="discontinueLocation()"
          type="button"
          class="w-full px-5 py-2.5 text-sm font-medium text-red-500 bg-white border border-red-500 rounded-lg hover:bg-red-50 focus:z-10 focus:ring-4 focus:ring-red-200"
        >
          Remove Location
        </button>
      </div>
    </div>
  </div>
</swal>
