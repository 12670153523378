import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import {
  StoresSummaryService,
  StoreSummaryUIModel,
  UpdateStoreInputModel,
} from '../../services/swagger.gen';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/_shared/services/toast.service';

@Component({
  selector: 'app-edit-store-modal',
  templateUrl: './edit-store-modal.component.html',
})
export class EditStoreModalComponent {
  private destroy$ = new Subject<void>();

  @ViewChild('modalContent')
  modalContent!: SwalComponent;

  @Input()
  store: StoreSummaryUIModel | null = null;

  @Input()
  mode: EditStoreModalMode = EditStoreModalMode.All;
  editStoreModalMode = EditStoreModalMode;

  @Output() storeUpdated = new EventEmitter<void>();

  updateStoreModel: UpdateStoreInputModel = new UpdateStoreInputModel();

  constructor(
    public readonly swalTargets: SwalPortalTargets,
    private storesService: StoresSummaryService,
    private toastService: ToastService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    console.log('onSubmit', this.updateStoreModel);
    this.storesService
      .updateSummary(this.updateStoreModel)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.dismiss();
          // Delay the toast and dismiss
          setTimeout(() => {
            this.toastService.success('Store updated successfully');
            this.storeUpdated.emit();
          }, 100);
        },
        error: (err) => {
          this.dismiss();
          console.log('Error updating store', err);
          // Delay the toast and dismiss
          setTimeout(() => {
            this.toastService.success('Error updating store'); // Changed to show error message
            this.storeUpdated.emit();
          }, 100);
        },
      });
  }

  open() {
    this.updateStoreModel.storesId = this.store?.storesId;
    this.updateStoreModel.storeName = this.store?.storeName ?? '';
    this.updateStoreModel.address1 = this.store?.address1 ?? '';
    this.updateStoreModel.address2 = this.store?.address2 ?? '';
    this.updateStoreModel.address3 = this.store?.address3 ?? '';
    this.updateStoreModel.address4 = this.store?.address4 ?? '';
    this.updateStoreModel.address5 = this.store?.address5 ?? '';
    this.updateStoreModel.postcode = this.store?.postcode ?? '';
    this.updateStoreModel.countryName = this.store?.country ?? '';
    this.updateStoreModel.phone = this.store?.phone ?? '';

    this.updateStoreModel.salutation =
      this.store?.storesContact?.salutation ?? '';
    this.updateStoreModel.firstName =
      this.store?.storesContact?.firstname ?? '';
    this.updateStoreModel.surname = this.store?.storesContact?.surname ?? '';
    this.updateStoreModel.jobTitle = this.store?.storesContact?.jobTitle ?? '';
    this.updateStoreModel.email = this.store?.storesContact?.email ?? '';

    this.modalContent.fire();
  }

  dismiss() {
    this.storeUpdated.emit();
    this.modalContent.close();
  }
}

export enum EditStoreModalMode {
  All,
  Name,
  Email,
  Telephone,
}
