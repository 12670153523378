<div class="container">
  <h2 class="text-3xl font-bold font-teko text-center uppercase mb-4">
    Feedback
  </h2>
  <div class="flex gap-4 mb-4 px-4">
    <button
      (click)="activeForm = 'feature'"
      [class.bg-lime-500]="activeForm === 'feature'"
      [class.bg-gray-300]="activeForm !== 'feature'"
      class="text-black px-4 py-2 rounded teko font-bold text-sm flex-grow transition-colors duration-200"
    >
      FEATURE REQUEST
    </button>
    <button
      (click)="activeForm = 'bug'"
      [class.bg-lime-500]="activeForm === 'bug'"
      [class.bg-gray-300]="activeForm !== 'bug'"
      class="text-black px-4 py-2 rounded teko font-bold text-sm flex-grow transition-colors duration-200"
    >
      BUG REPORT
    </button>
  </div>

  <iframe
    *ngIf="activeForm === 'feature'"
    src="https://forms.clickup.com/18039809/f/h6h01-581431/SOP5GHRRISFA9IU64C"
    class="w-full h-[calc(100vh-300px)] border-0"
    title="Feature Request"
  >
  </iframe>

  <iframe
    *ngIf="activeForm === 'bug'"
    src="https://forms.clickup.com/18039809/f/h6h01-581331/0UAEQO0F6OAJGQ5I5K"
    class="w-full h-[calc(100vh-300px)] border-0"
    title="Bug Report"
  >
  </iframe>
</div>
