<div
  class="w-full h-24 bg-white p-4 border-b border-gray-200 text-black dark:bg-zinc-900 dark:border-zinc-700 flex justify-between"
>
  <div class="flex items-center">
    <div class="mr-4">
      <img src="assets/Claw-Icon.png" alt="logo" class="w-10" />
    </div>
    <!-- <div class="mr-4">
            <button (click)="leftSidebar = true">
                <svg class="w-6 h-6 text-gray-800 dark:text-zinc-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14"/>
                </svg>
            </button>
        </div>              
        <div class="relative">
            <input type="text" class="h-10 w-52 md:w-96 lg:w-96 pl-10 pr-4 border border-gray-300 rounded-md text-sm placeholder-gray-500 dark:bg-neutral-700 dark:border-zinc-700 dark:text-white" placeholder="Search" />
            <div class="absolute left-2 top-2">
                <svg class="w-6 h-6 text-gray-400 dark:text-zinc-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"/>
                </svg>     
            </div>
        </div> -->
  </div>
  <div class="flex items-center justify-end">
    <button (click)="toggleDarkMode()" class="flex items-center p-2">
      <!-- Display sun SVG in light mode -->
      <svg
        *ngIf="darkMode"
        class="w-6 h-6 text-gray-400 dark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fill-rule="evenodd"
          d="M13 3a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0V3ZM6.343 4.929A1 1 0 0 0 4.93 6.343l1.414 1.414a1 1 0 0 0 1.414-1.414L6.343 4.929Zm12.728 1.414a1 1 0 0 0-1.414-1.414l-1.414 1.414a1 1 0 0 0 1.414 1.414l1.414-1.414ZM12 7a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm-9 4a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H3Zm16 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2ZM7.757 17.657a1 1 0 1 0-1.414-1.414l-1.414 1.414a1 1 0 1 0 1.414 1.414l1.414-1.414Zm9.9-1.414a1 1 0 0 0-1.414 1.414l1.414 1.414a1 1 0 0 0 1.414-1.414l-1.414-1.414ZM13 19a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0v-2Z"
          clip-rule="evenodd"
        />
      </svg>
      <!-- Display moon SVG in dark mode -->
      <svg
        *ngIf="!darkMode"
        class="w-6 h-6 text-gray-400 dark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          fill-rule="evenodd"
          d="M11.675 2.015a.998.998 0 0 0-.403.011C6.09 2.4 2 6.722 2 12c0 5.523 4.477 10 10 10 4.356 0 8.058-2.784 9.43-6.667a1 1 0 0 0-1.02-1.33c-.08.006-.105.005-.127.005h-.001l-.028-.002A5.227 5.227 0 0 0 20 14a8 8 0 0 1-8-8c0-.952.121-1.752.404-2.558a.996.996 0 0 0 .096-.428V3a1 1 0 0 0-.825-.985Z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <!-- Display notification sidebar -->
    <!-- <button (click)="notificationSidebar = true">
      <svg
        class="w-7 h-7 text-gray-400 dark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M17.133 12.632v-1.8a5.406 5.406 0 0 0-4.154-5.262.955.955 0 0 0 .021-.106V3.1a1 1 0 0 0-2 0v2.364a.955.955 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C6.867 15.018 5 15.614 5 16.807 5 17.4 5 18 5.538 18h12.924C19 18 19 17.4 19 16.807c0-1.193-1.867-1.789-1.867-4.175ZM8.823 19a3.453 3.453 0 0 0 6.354 0H8.823Z"
        />
      </svg>
    </button> -->

    <!-- User profile pict -->
    <img
      [src]="profileImageUrl"
      class="hidden md:block lg:block xl:block rounded-full w-10 h-10 ml-4 border border-black"
    />
  </div>
</div>
<!-- <app-leftsidebar
  [leftSidebar]="leftSidebar"
  (closeModalEvent)="leftSidebar = false"
></app-leftsidebar>
<app-notifications
  [notificationSidebar]="notificationSidebar"
  (closeModalEvent)="notificationSidebar = false"
></app-notifications> -->
