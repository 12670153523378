<div
  class="bg-gray-50 dark:bg-gray-900 min-h-screen flex flex-col"
  *ngIf="isLoggedIn"
>
  <app-header></app-header>
  <app-main class="flex-grow"></app-main>
  <app-footer></app-footer>
</div>

<div
  class="bg-neutral-800 min-h-screen flex items-center justify-center"
  *ngIf="!isLoggedIn"
>
  <!-- Video Background -->
  <video
    #videoElement
    *ngIf="shouldPlayVideo"
    class="absolute inset-0 w-full h-full object-cover z-[1] opacity-100"
    autoplay
    muted
    loop
    playsinline
    preload="auto"
    defaultMuted
    [muted]="true"
  >
    <source src="../assets/Loop.webm" type="video/webm" />
  </video>

  <div
    class="bg-white rounded-lg m-4 p-4 w-[36rem] flex flex-col items-center z-[3]"
  >
    <!-- Monster Logo -->
    <img
      src="../assets/images/monster-energy.png"
      class="w-40 mb-6"
      alt="Monster Logo"
    />

    <!-- Welcome Text -->
    <h2 class="font-teko text-2xl font-bold mb-6">WELCOME BACK</h2>

    <button
      (click)="login()"
      class="w-full py-2 mb-4 bg-zinc-900 text-white rounded-md font-proxima hover:bg-zinc-800 flex items-center justify-center gap-2"
    >
      <img src="../assets/Claw-Icon.png" class="w-5" alt="Monster Logo" />
      <span>SSO LOGIN</span>
    </button>
  </div>
</div>

<MsalRedirectComponent></MsalRedirectComponent>
