import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-single-image-selector',
  templateUrl: './single-image-selector.component.html',
})
export class SingleImageSelectorComponent {
  @Input() id!: string;
  @Input() type!: string;
  @Input() title!: string;
  @Input() image!: string;

  // send the title to the parent
  @Output() confirmEvent = new EventEmitter<string>();

  confirm() {
    this.confirmEvent.emit(this.id);
  }
}
