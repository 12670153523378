<swal #modalContent [showConfirmButton]="false" [showCloseButton]="true">
  <div *swalPortal="swalTargets.content">
    <h2
      class="text-xl font-bold text-gray-900 mb-4 flex justify-start font-teko"
    >
      Quick Update [{{ store!.storeName }}]
    </h2>
    <hr class="border-gray-200 dark:border-gray-600 my-4" />
    <form (ngSubmit)="onSubmit()" #updateForm="ngForm" class="space-y-4">
      <div *ngIf="mode === editStoreModalMode.All">
        <label
          for="storeName"
          class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
          >Store Name</label
        >
        <input
          type="text"
          id="storeName"
          name="storeName"
          [(ngModel)]="updateStoreModel!.storeName"
          class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
        />
      </div>
      <div *ngIf="mode === editStoreModalMode.Email">
        <label
          for="email"
          class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
          >Email</label
        >
        <input
          type="text"
          id="email"
          name="email"
          [(ngModel)]="updateStoreModel!.email"
          class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
        />
      </div>
      <div *ngIf="mode === editStoreModalMode.Telephone">
        <label
          for="telephone"
          class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
          >Telephone</label
        >
        <input
          type="text"
          id="telephone"
          name="telephone"
          [(ngModel)]="updateStoreModel!.phone"
          class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
        />
      </div>
      <div
        class="grid grid-cols-6 gap-4"
        *ngIf="
          mode === editStoreModalMode.All || mode === editStoreModalMode.Name
        "
      >
        <div class="col-span-2">
          <label
            for="salutation"
            class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
            >Title</label
          >
          <input
            type="text"
            id="salutation"
            name="salutation"
            [(ngModel)]="updateStoreModel!.salutation"
            class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          />
        </div>
        <div class="col-span-2">
          <label
            for="firstName"
            class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
            >First Name</label
          >
          <input
            type="text"
            id="firstName"
            name="firstName"
            [(ngModel)]="updateStoreModel!.firstName"
            class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          />
        </div>
        <div class="col-span-2">
          <label
            for="surname"
            class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
            >Surname</label
          >
          <input
            type="text"
            id="surname"
            name="surname"
            [(ngModel)]="updateStoreModel!.surname"
            class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          />
        </div>
      </div>
      <div
        class="grid grid-cols-4 gap-4"
        *ngIf="mode === editStoreModalMode.All"
      >
        <div class="col-span-2">
          <label
            for="address1"
            class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
            >Address Line 1</label
          >
          <input
            type="text"
            id="address1"
            name="address1"
            [(ngModel)]="updateStoreModel!.address1"
            class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          />
        </div>
        <div class="col-span-2">
          <label
            for="address2"
            class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
            >Address Line 2</label
          >
          <input
            type="text"
            id="address2"
            name="address2"
            [(ngModel)]="updateStoreModel!.address2"
            class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          />
        </div>
        <div class="col-span-2">
          <label
            for="address3"
            class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
            >Address Line 3</label
          >
          <input
            type="text"
            id="address3"
            name="address3"
            [(ngModel)]="updateStoreModel!.address3"
            class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          />
        </div>
        <div class="col-span-2">
          <label
            for="address4"
            class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
            >Address Line 4</label
          >
          <input
            type="text"
            id="address4"
            name="address4"
            [(ngModel)]="updateStoreModel!.address4"
            class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          />
        </div>
        <div class="col-span-2">
          <label
            for="address5"
            class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
            >Address Line 5</label
          >
          <input
            type="text"
            id="address5"
            name="address5"
            [(ngModel)]="updateStoreModel!.address5"
            class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          />
        </div>
        <div class="col-span-2">
          <label
            for="postcode"
            class="block text-sm font-medium text-gray-700 mb-1 text-left font-teko"
            >Post Code</label
          >
          <input
            type="text"
            id="postcode"
            name="postcode"
            [(ngModel)]="updateStoreModel!.postcode"
            class="block w-full px-3 py-2 rounded-lg bg-white border border-gray-300 text-sm"
          />
        </div>
      </div>
    </form>
    <div class="mt-6 flex justify-start">
      <button
        type="submit"
        class="flex items-center justify-center text-black bg-custom-green font-medium rounded-lg text-sm px-4 py-2 h-[38px]"
        (click)="onSubmit()"
      >
        Update
      </button>
      <button
        type="button"
        class="flex items-center justify-center text-black bg-white font-medium rounded-lg text-sm px-4 py-2 h-[38px] border border-gray-300 ml-2"
        (click)="dismiss()"
      >
        Cancel
      </button>
    </div>
  </div>
</swal>
