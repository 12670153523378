import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { LocationResponse } from '../../survey-page/survey-page.component';

@Component({
  selector: 'app-location-selector-modal',
  templateUrl: './location-selector-modal.component.html',
})
export class LocationSelectorModalComponent {
  @ViewChild('modalContent')
  modalContent!: SwalComponent;

  @Input() response!: LocationResponse;
  @Input() distributionType!: string;
  @Output() onConfirm = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  locationOptions = [
    { value: 'Front of Store', label: 'Front of Store' },
    { value: 'Back of Store', label: 'Back of Store' },
    { value: 'Self Pay', label: 'Self Pay' },
    { value: 'Hot Food', label: 'Hot Food' },
    { value: 'Food To Go', label: 'Food To Go' },
  ];

  constructor(public readonly swalTargets: SwalPortalTargets) {}

  open() {
    this.modalContent.fire();
    if (this.response.storeLocation == '')
      this.response.storeLocation = 'Front of Store';
  }

  confirm() {
    this.onConfirm.emit();
  }

  cancel() {
    this.onCancel.emit();
  }

  close() {
    this.modalContent.close();
  }

  handleLocationChange(newValue: string) {
    this.response.storeLocation = newValue;
  }
}
