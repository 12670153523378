<div *ngIf="pages[currentPage] === 'Order'">
  <div class="flex items-center justify-between p-4 relative">
    <div class="w-12"><!-- Spacer to balance the layout --></div>
    <h2
      class="text-3xl font-teko font-bold text-black dark:text-white uppercase flex-1 text-center"
    >
      <span *ngIf="isWholesale">Wholesale - </span>Select Skus To Order
    </h2>
  </div>

  <!-- Add the fixed cart button here, after the header -->
  <button
    class="fixed top-28 right-4 inline-flex items-center justify-center p-3 text-sm font-medium text-center text-white bg-custom-green rounded-lg hover:bg-custom-green/90 focus:ring-4 focus:outline-none focus:ring-custom-green/50 z-50 touch-none"
    (click)="pages[currentPage] = 'Summary'"
  >
    <i
      class="fa-solid fa-cart-shopping text-white w-5 h-5 flex items-center justify-center"
    ></i>
    <span class="sr-only">Shopping Cart</span>
    <div
      class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900"
    >
      {{ totalOrderCount }}
    </div>
  </button>

  <!-- Group headers - horizontal scroll -->
  <div class="flex overflow-x-auto hide-scrollbar">
    <ng-container *ngFor="let group of groupedProducts">
      <img
        [src]="
          'assets/images/product-groups/' +
          group.skuGroup.name.toLowerCase() +
          '.png'
        "
        [alt]="group.skuGroup.name"
        class="w-[60px] h-[80px] rounded-lg object-contain flex-shrink-0"
        (click)="scrollToGroup(group.skuGroup)"
      />
    </ng-container>
  </div>

  <!-- Products by group - vertical scroll for groups -->
  <div *ngFor="let group of groupedProducts" class="px-4">
    <div [id]="group.skuGroup.name">
      <h6 class="text-xl font-teko font-bold uppercase my-4">
        {{ group.skuGroup.name }}
      </h6>
      <div class="flex overflow-x-auto hide-scrollbar">
        <div
          *ngFor="let product of group.skus"
          class="flex-shrink-0 w-[100px] -ml-4"
        >
          <div class="relative inline-flex text-center">
            <img
              [src]="product.imagePath"
              [alt]="product.productName"
              class="w-[100px] h-[167px] object-contain cursor-pointer"
              (click)="openOrderModal(product)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Scroll to top button -->
  <button
    *ngIf="showScrollButton"
    class="fixed bottom-44 right-4 w-12 h-12 rounded-full bg-custom-green flex items-center justify-center shadow-lg z-50 touch-none"
    (click)="scrollToTop()"
  >
    <i class="fa-solid fa-arrow-up text-white"></i>
  </button>

  <!-- Order Modal -->
  <app-order-selector-modal
    *ngIf="showOrderModal && selectedProduct"
    [data]="selectedProduct"
    (closeModal)="showOrderModal = false"
    (confirmOrder)="handleOrderConfirm($event)"
  ></app-order-selector-modal>
</div>

<div *ngIf="pages[currentPage] === 'Summary'">
  <app-order-summary [orderItems]="orderItems"></app-order-summary>
</div>

<div *ngIf="pages[currentPage] === 'Signature'">
  <app-order-signature></app-order-signature>
</div>

<div *ngIf="pages[currentPage] === 'Receipt'">
  <app-order-receipt
    [photoData]="wholesaleSignatureData || signatureData"
    [orderItems]="orderItems"
    [isWholesale]="isWholesale"
  ></app-order-receipt>
</div>

<div *ngIf="pages[currentPage] === 'PhotoReceipt'">
  <h2
    class="text-3xl font-teko font-bold text-black dark:text-white uppercase flex-1 text-center"
  >
    Upload Photo Receipt
  </h2>

  <app-photo-upload
    (onConfirm)="handlePhotoReceiptConfirm($event)"
  ></app-photo-upload>
</div>

<app-bottom-nav-bar
  [leftButtonText]="'Back'"
  [rightButtonText]="'Next'"
  [leftButtonIcon]="'fa-solid fa-arrow-left'"
  [rightButtonIcon]="'fa-solid fa-arrow-right'"
  (leftButtonClickEvent)="previousStep()"
  (rightButtonClickEvent)="nextStep()"
></app-bottom-nav-bar>
