<div class="flex flex-col justify-center items-center p-8">
  <h2
    class="{{
      distributionType === 'Entry' ? 'text-green-700' : 'text-yellow-500'
    }} text-5xl font-teko font-bold text-black dark:text-white font-weight-700 uppercase text-center"
  >
    {{ name }}
  </h2>
  <div class="text-sm text-gray-500 dark:text-gray-400">
    All of the Stores
    <span class="font-bold">{{
      distributionType === "Entry" ? "On Entry" : "On Exit"
    }}</span>
    Locations.
  </div>
</div>

<div
  *ngIf="page === -1"
  class="grid grid-cols-2 gap-4 p-4 justify-items-center"
>
  <div>
    <app-store-location-card
      (click)="addLocation()"
      [locationResponse]="currentLocationResponse"
    ></app-store-location-card>
  </div>
  <ng-container *ngFor="let location of locationResponses">
    <div
      *ngIf="
        distributionType === 'Exit' || distributionType === location.createdAt
      "
    >
      <app-store-location-card
        [locationResponse]="location"
        [distributionType]="distributionType"
        (click)="clickLocation(location)"
      ></app-store-location-card>
    </div>
  </ng-container>
</div>

<div *ngIf="page >= 0">
  <ng-container *ngFor="let question of questions">
    <div *ngIf="page === questions.indexOf(question)">
      <app-sku-selector
        *ngIf="question.questionTemplate === 'SKUSelector'"
        [response]="currentLocationResponse"
      ></app-sku-selector>
      <app-location-selector
        *ngIf="question.questionTemplate === 'LocationsSelector'"
        [options]="question.questionOptions!"
        (cancelEvent)="previousStep()"
        (confirmEvent)="nextStep()"
        [response]="currentLocationResponse"
      ></app-location-selector>
      <app-photo-upload
        *ngIf="question.questionTemplate === 'PhotoUploader'"
        [maxFiles]="1"
        (cancelEvent)="previousStep()"
        (onConfirm)="photoUploaded($event)"
      ></app-photo-upload>
    </div>
  </ng-container>
</div>

<app-bottom-nav-bar
  *ngIf="currentQuestion?.questionTemplate !== 'LocationsSelector'"
  [leftButtonText]="'Back'"
  [rightButtonText]="'Next'"
  [leftButtonIcon]="'fa-solid fa-arrow-left'"
  [rightButtonIcon]="'fa-solid fa-arrow-right'"
  (leftButtonClickEvent)="previousStep()"
  (rightButtonClickEvent)="nextStep()"
></app-bottom-nav-bar>

<app-location-discontinue-modal
  (discontinueEvent)="locationDiscontinued($event)"
></app-location-discontinue-modal>
