import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
})
export class LeftsidebarComponent {

  storeOptions = false;
  callsOptions = false;
  insightsOptions = false;

  @Input() leftSidebar = true;
  @Output() closeModalEvent = new EventEmitter<void>();

  closeModal() {
    this.closeModalEvent.emit();
  }

}
