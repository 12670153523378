<div
  class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
>
  <div class="bg-white rounded-lg p-6 max-w-2xl w-full">
    <div class="flex justify-between items-center mb-6">
      <h2 class="text-xl font-teko font-bold uppercase">
        {{ data.productName }}
      </h2>
      <button (click)="close()" class="text-gray-500">&times;</button>
    </div>

    <table class="w-full">
      <thead>
        <tr
          class="text-sm font-proxima border-b border-gray-200 hover:bg-gray-50"
        >
          <th class="text-centerpb-4 w-1/8"></th>
          <th class="text-left pb-4 w-1/4">SKU</th>
          <th class="text-center pb-4 w-1/6">Type</th>
          <th class="text-center pb-4 w-1/6">Price</th>
          <th class="text-center pb-4 w-1/5">Quantity</th>
          <th class="text-center pb-4 w-1/6">Cart</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let variant of data.variants"
          class="text-sm font-proxima border-b border-gray-200 hover:bg-gray-50 items-center"
        >
          <td class="py-4">
            <img
              [src]="variant.imagePath"
              class="w-8 h-12 mr-4 object-contain"
            />
          </td>
          <td class="py-4">
            <div class="flex items-left font-bold">
              {{
                getFormattedProductName(variant.productName, variant.skuName)
              }}
            </div>
          </td>
          <td class="text-center py-4">
            <span
              [ngClass]="{
                'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300':
                  getVariantType(variant.productName) === 'Single',
                'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300':
                  getVariantType(variant.productName) === 'Multipack',
                'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300':
                  getVariantType(variant.productName) === 'Free'
              }"
              class="font-bold px-2.5 py-1 rounded"
            >
              {{
                getVariantType(variant.productName) === "Single"
                  ? "Single"
                  : getVariantType(variant.productName) === "Multipack"
                  ? "Multipack"
                  : "Free"
              }}
            </span>
          </td>
          <td class="text-center py-4">
            <span
              class="bg-gray-50 text-gray-800 px-2.5 py-2 border border-gray-300 rounded"
            >
              €{{ variant.price.toFixed(2) }}
            </span>
          </td>
          <td class="text-center py-4 flex items-center justify-center">
            <app-number-input
              [value]="getCurrentQuantity(variant)"
              (valueChange)="handleQuantityChange($event, variant)"
            ></app-number-input>
          </td>
          <td class="text-center py-4">
            <button
              class="px-2 py-1 rounded border border-gray-300"
              (click)="addItemToCart(variant)"
              [ngClass]="{
                'bg-gray-800 text-white': itemInCart(variant),
                'bg-white text-black' : !itemInCart(variant)
              }"
            >
              {{ itemInCart(variant) ? "Added" : "Add" }}
            </button>
          </td>
          <hr />
        </tr>
      </tbody>
    </table>

    <div class="flex justify-start mt-6 space-x-4">
      <button
        class="px-2 py-1 font-proxima text-xs font-bold bg-custom-green text-black rounded hover:bg-custom-green-dark"
        (click)="confirm()"
      >
        Confirm
      </button>
      <button
        class="px-2 py-1 font-proxima text-xs font-bold border rounded hover:bg-gray-100"
        (click)="close()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
