<div class="mx-auto p-2">
  <label
    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    for="file_input"
    >{{ label || "Photos" }}</label
  >
  <div class="flex items-center justify-center w-full">
    <label
      for="dropzone-file"
      class="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
    >
      <div class="flex flex-col items-center justify-center pt-5 pb-6">
        <i
          class="fas fa-camera text-4xl mb-4 text-gray-500 dark:text-gray-400"
        ></i>
        <p class="text-sm text-gray-500 dark:text-gray-400">
          Tap here to take photo
        </p>
        <p class="text-xs text-custom-green underline">or browse for files</p>
      </div>
      <input
        id="dropzone-file"
        type="file"
        class="hidden"
        (change)="onFileSelected($event)"
        multiple
        accept="image/*"
      />
    </label>
  </div>

  <div *ngIf="selectedFiles.length > 0" class="mt-4" #fileList>
    <div class="grid grid-cols-3 gap-4">
      <div
        *ngFor="let fileObj of selectedFiles; let i = index"
        class="relative"
      >
        <div class="w-full pb-[56.25%] relative">
          <img
            [src]="fileObj.url"
            class="absolute inset-0 w-full h-full object-cover rounded-lg"
          />
        </div>
        <button
          (click)="removeFile(i)"
          class="absolute top-0 right-0 text-red-700 p-1"
        >
          <i class="fas fa-circle-xmark"></i>
        </button>
        <div
          *ngIf="coverImageSelector"
          class="mt-2 flex items-center justify-center"
        >
          <input
            [(ngModel)]="coverImageIndex"
            (ngModelChange)="setCoverImage(i)"
            [value]="i"
            [id]="'radio-' + i"
            type="radio"
            [name]="'cover-image'"
            class="w-4 h-4 text-custom-green bg-gray-100 border-gray-300 focus:ring-custom-green dark:focus:ring-custom-green dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            [for]="'radio-' + i"
            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Feature / Cover Image
          </label>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="uploadedFiles.length > 0" class="mt-8">
    <h3 class="text-lg font-semibold mb-2">Uploaded Photos:</h3>
    <div class="grid grid-cols-2 gap-4">
      <ng-container *ngFor="let file of uploadedFiles; let i = index">
        <img
          [src]="file.url"
          alt="Uploaded photo"
          class="w-full h-auto rounded-lg"
        />
        <button
          (click)="removeFile(i)"
          class="absolute top-2 right-2 text-red-500 bg-white rounded-full p-1 hover:bg-red-100"
        >
          <i class="fas fa-times"></i>
        </button>
        <p class="text-sm text-gray-600 mt-1">{{ file.type }}</p>
      </ng-container>
    </div>
  </div>
</div>
